import React from "react";
import styles from './CartOfferCard.module.css';
import { Button } from "../../../elements";

export default function({action, offer, handleEvent}){

    return(
        <article style={{padding: '1rem 0'}}>
            <div className={styles.cardWrapper}>
                <img className={styles.cardIcon} src={offer.image}/>
                <p>{offer.title}</p>
                <p>{offer.description}</p>
                <p>{offer.price}</p>
                {
                    action != null &&
                    <Button
                    variant="light"
                    type="solid-color-tw"
                    hoverType="outline-white-tc"
                    click={() => {handleEvent(offer.id)}}
                    >
                        {
                            action == "add" && <span title="Add this offer to your cart"><i className="las la-cart-plus" style={{fontSize:'24px'}}></i></span>
                        }

                        {
                            action == "delete" && <span title="Remove this offer from your cart"><i className="las la-minus" style={{fontSize:'24px'}}></i></span>
                        }
                    </Button>
                }
            </div>
        </article>
    )
}