class EventLoggerSystem {
    constructor() {
        if (EventLoggerSystem.instance) {
            return EventLoggerSystem.instance;
        }
        EventLoggerSystem.instance = this;
        this._logLevel = 3;
        this._dictionaryConfig = {};
        this.DebugLogLevel = {
            none: 0,
            error: 1,
            warn: 2,
            info: 3,
            debug: 4,
            trace: 5
        };
        this.setLogLevel(this.DebugLogLevel.info);
    }
    setLogLevel(value) {
        if (this._logLevel !== undefined) {
            return; // Optionally handle reconfiguration or ignore
        }
        this._logLevel = value;
        //this._dictionaryConfig['logLevel'] = value;
        this.log(`Config: debugLogLevel = ${this._logLevel}`, this.DebugLogLevel.debug);
    }
    log(msg, msgLogLevel) {
        if (this._logLevel < msgLogLevel) {
            return;
        }
        let outStr = `[Upmatic][${new Date().toISOString()}]`;
        switch (msgLogLevel) {
            case this.DebugLogLevel.error:
                outStr += '[ERROR]';
                break;
            case this.DebugLogLevel.warn:
                outStr += '[WARNING]';
                break;
            case this.DebugLogLevel.info:
                outStr += '[INFO]';
                break;
            case this.DebugLogLevel.debug:
                outStr += '[DEBUG]';
                break;
            case this.DebugLogLevel.trace:
                outStr += '[TRACE]';
                break;
        }
        outStr += ` ${msg}`;
        console.log(outStr);
    }
}
export default new EventLoggerSystem();