import React, { useEffect, useRef } from "react";
import styles from "./ModalSignup.module.css";
import { Backdrop, LoginForm } from "..";
import SignUp from "../../pages/SignUp";
import { useCustomState } from "../../../state/state";

export default ({ setToken }) => {
  const [state, actions] = useCustomState();
  const emailRef = useRef(null);

  useEffect(() => {
    if (state.signup == true) {
      emailRef.current.focus();
    }
  }, [state.signup])

  return (
    <div className={[styles.wrapper, state.signup && styles.show].join(" ")}>
      <div className={styles.modal}>
        <i onClick={() => actions.toogleSignup()} className="las la-times" style={{color:'#ffffff'}}/>
        {/* <ContactForm /> */}
        <SignUp setToken={setToken} emailRef={emailRef}/>
        {/* <LoginForm /> */}
      </div>
      <Backdrop />
    </div>
  );
};
