import React, { useEffect, useState } from 'react';
import { useCustomState } from "../../../state/state";
//import './CSS/Footer.css';
//import '../App.css';
import './Footer.css';
import { Button } from '../../Buttons/Button';
import { Link } from 'react-router-dom';
import { Logo } from '../../elements';
import { useLocation } from 'react-router-dom';

function Footer() {
  const [state, actions] = useCustomState();
  const {pathname} = useLocation();

  return (
    <>
      <div className={pathname == "/" ? 'footer-container-main homepage' : 'footer-container-main'}>
        <div style={{height:'2px', background:'#FFB703'}}></div>
        <div 
          className={pathname == "/" ? 'footer-container homepage' : 'footer-container'}
          //className={'footer-container homepage'}
        >    
          <div className='footer-links'>
            <div className='footer-link-wrapper'>
              <div
                style={{  }} 
                className='footer-link-items'>
                {/* <h2>About</h2> */}
                <Link to='/about'>About</Link>
                <Link to='/privacy'>Privacy</Link>
                <Link to='/terms'>Terms of Service</Link>
              </div>
              <div          
                className='footer-link-items'>
                {/* <h2>Contact</h2> */}
                {/* <Link to='/howitworks'>How it works</Link> */}
                <Link to='/faq'>Faqs</Link>
                <Link to='/support'>Support</Link>
              </div>
              <div
                className='social-link-items'>
                <Link
                  className='social-icon-link facebook'
                  to='https://www.facebook.com/upmatic.io'
                  target='_blank'
                  aria-label='Facebook'
                >
                  <i className={ state.data.social_las_icons.fb } />
                </Link>
                {/* <Link
                  className='social-icon-link instagram'
                  to='/'
                  target='_blank'
                  aria-label='Instagram'
                >
                  <i className={ state.data.social_las_icons.ig } />
                </Link> */}
                {/* <Link
                  className='social-icon-link youtube'
                  to='/'
                  target='_blank'
                  aria-label='Youtube'
                >
                  <i className={ state.data.social_las_icons.you } />
                </Link> */}
                {/* <Link
                  className='social-icon-link twitter'
                  to='/'
                  target='_blank'
                  aria-label='Twitter'
                >
                  <i className={ state.data.social_las_icons.twit } />
                </Link> */}
                {/* <Link
                  className='social-icon-link pinterest'
                  to='/'
                  target='_blank'
                  aria-label='Pinterest'
                >
                  <i className={ state.data.social_las_icons.pin } />
                </Link> */}
              </div>          
            </div>
          </div>
          <section className='social-media'>
            <div className='social-media-wrap'>
              <div className='footer-logo'>
                <Link to='/' className='social-logo'>
                  {/* SiteName - Reward Offers */}
                  <Logo />
                  {/* <span>Hello</span> */}
                  {/* <i className='fab fa-typo3' /> */}
                </Link>
              </div>
              <div className='social-icons'>
              <p className='website-rights'>Offers LLC © 2024</p>
              </div>
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Footer;
