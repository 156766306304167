const hostname = window.location.hostname;

// Declare dataLayer at the top level of your script
window.dataLayer = window.dataLayer || [];

// Function to determine if GA should be initialized
export const isProduction = (hostname) => {
    // Add all production hostnames here
    return hostname === "upmatic.io" || hostname === "www.upmatic.io";
};  

//<script async src="https://www.googletagmanager.com/gtag/js?id=G-X1FYSVNSJK"></script>

export const initGoogleAnalytics = () => {
  if (isProduction(hostname)) {
    // Create script tag dynamically
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-X1FYSVNSJK';
    document.head.appendChild(script);

    // Initialize dataLayer and configure gtag on script load
    script.onload = () => {
    // Declare the gtag function using window to avoid ESLint errors
    window.gtag = function() { window.dataLayer.push(arguments); };
    window.gtag('js', new Date());
    window.gtag('config', 'G-X1FYSVNSJK');
    }
  }
  else { console.log("No Analytics"); }
};

export const eventGoogleAnalytics = (evtName, evtParams) => {
  if(window.gtag){
    window.gtag('event', evtName, {
      ...evtParams
    });
  }
}

  // gtag('event', '<event_name>', {
  //   <event_parameters>
  // });

// example on how to call it
// eventGoogleAnalytics('login_success', {
//   'app_name': 'upmatic.io',
//   'screen_name': 'LoginModal'
// });
