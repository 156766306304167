import React, { useState, useEffect, useCallback, Fragment } from 'react';
import styles from './OfferWall.module.css';
import { useCustomState } from '../../../state/state';
import { useLocation, useParams } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { headerGet, getOfferRewards, getCategories, getOfferRewardWithPagination } from '../../../services/basicGet';
import {
  OfferWallCard,
  OfferWallPagination,
  OfferWallCategories,
  OfferWallFeatured,
  OfferWallSearch,
} from './components';

import { Header } from '../../widgets';
import useToken from '../../../useToken';
import OfferWallDetailsModal from './components/OfferWallDetailsModal/OfferWallDetailsModal';
import { Button } from '../../elements';

export default ({ sidebar = 'left', layout = 'grid', title = 'title', pageTitle="pageTitle" }) => {
  const [state, actions] = useCustomState();
  const { /*category,*/ author, posting_date, /*query*/ } = useParams();
  const { url } = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const offersPerPage = useState(8)[0];
  const [totalOffers, setTotalOffers] = useState(0);
  const [offersArray, setOffersArray] = useState([]);
  const [allOffers, setAllOffers] = useState();
  //const [pageTitle, setPageTitle] = useState(title);

  const [offersAPI, setOffersAPI] = useState([]);
  const [categories, setCategories] = useState();
  const [offerDetails, setOfferDetails] = useState();
  const {token} = useToken();
  const [query, setQuery] = useState();
  const [category, setCategory] = useState();
  const [isPaginationOffers, setIsPaginationOffers] = useState(true);


  useEffect(() => {
    //if(allOffers == null){
      if(offersAPI.length > 0 && isPaginationOffers == false){
        return;
      }

      if(state.loading == false){
        actions.toogleLoading(true);
      }

      getOfferRewardWithPagination(currentPage, offersPerPage, function(offers){
        if(offers != null && (offers?.data?.length > 0 || offers.length > 0)){
          if(offers?.data?.length > 0){
            setIsPaginationOffers(true);
            getOfferRewardsResponseHandler(offers.data);
            setTotalOffers(offers.totalRecords);
          }else{
            setIsPaginationOffers(false);
            setTotalOffers(offers.length);
            getOfferRewardsResponseHandler(offers);
          }
        }else{
          setIsPaginationOffers(false);
          setTotalOffers(state.data.offers);
          getOfferRewardsResponseHandler(state.data.offers);
        }
        //return offers;
        actions.toogleLoading(false);
      }, function(err){
        setIsPaginationOffers(false);
        setTotalOffers(state.data.offers.length);
        getOfferRewardsResponseHandler(state.data.offers);
        actions.toogleLoading(false);
      });
    // }else{
    //   filterOffers(allOffers);
    // }
  }, [
    author,
    category,
    posting_date,
    query,
    //categories,
    state.data.offers,
    state.data.users,
    title,
    currentPage,
    state.loggedIn,
    state.user
  ]);

  // for use by page's title
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  function getOfferRewardsResponseHandler(offers){
    let _categories = [];
    offers.map(offer => offer.categories?.map(cat => {
      if(!_categories.find(_cat => _cat.title == cat.name)){
        _categories.push({id: cat.id, title: cat.name})
      }
    }));
    _categories.push({
      id:0,
      title: 'All'
    });
    setCategories(_categories);

    setAllOffers(offers);
    filterOffers(offers);
  }

  function filterOffers(offers){
    let newTitle = title;
    if (category != null && category > 0) {
      offers = offers.filter(
        (offer) => offer.categories.some(cat => cat.id == category)
      );
      setOffersAPI(offers);
      newTitle =
        'Category: ' +
          categories.filter((cat) => cat.id === category)[0].title;
    } else if (author) {
      offers = offers.filter(
        (offer) => author === offer.user_id.toString()
      );
      newTitle =
        'Author: ' +
          state.data.users.filter((user) => user.id.toString() === author)[0];
    } else if (posting_date) {
      offers = offers.filter(
        (offer) => posting_date === offer.posting_date
      );
      newTitle = 'Date: ' + posting_date;
    } else if (query) {
      offers = offers.filter((offer) => offer.title.toLowerCase().includes(query.toLowerCase()));
      newTitle ='Search for: ' + query;
    }
    
    //console.log(offers);
    setOffersAPI(offers);
    //setPageTitle(newTitle);
  }

  useEffect(() => {
    setOffersArray(
      offersAPI.map((offer, index) => {
        return (
          <OfferWallCard
            key={index}
            layout={layout}
            token={token}
            offer={offer}
            author={
              state.data.users.filter(
                (user) => user.id.toString() === offer.user_id
              )[0]
            }
            category={
              categories.filter(
                (cat) => cat.id === offer.category_id?.toString()
              )[0]?.title
            }
            setOfferDetails={setOfferDetails}
          />
        );
      })
    );
    //setCurrentPage(1);
  }, [
    layout,
    category,
    author,
    query,
    posting_date,
    url,
    state.data.users,
    categories,
    offersAPI//filterPosts,
  ]);

  return (
    <Fragment>
      {/* <Header img={state.data.header_bgs.blog}>{pageTitle}</Header> */}
      { offerDetails && <OfferWallDetailsModal offerDetails={offerDetails} setOfferDetails={setOfferDetails} />}
      <section
        className={[
          styles.wrapper,
          sidebar === 'left'
            ? styles.with_sidebar + ' ' + styles.left
            : sidebar === 'right'
            ? styles.with_sidebar + ' ' + styles.right
            : null,
        ].join(' ')}
        style={{flexDirection:'column'}}
      >
        <aside className={styles.sidebar}>
          <OfferWallSearch query={query} setQuery={setQuery} setCategory={setCategory} />
          <OfferWallCategories data={categories} setCategory={setCategory} />
          <OfferWallFeatured data={state.data.featured} />
        </aside>

        {/* {
          categories &&
          <div className={styles.categories}>
            {categories.map((category, index) => {
             return(
              <div className={styles.button}>
                <Button type="solid-gray-tb" round click={() => {setCategory(category.id)}}>
                  {category.title}
                </Button>
              </div>
             )
            })}
          </div>
        } */}

        <article>
          <div
            className={
              layout === 'grid'
                ? styles.grid
                : layout === 'list'
                ? styles.list
                : null
            }
          >
            {isPaginationOffers == false && offersArray.slice(
              (currentPage - 1) * offersPerPage,
              currentPage * offersPerPage
            )}
            {isPaginationOffers == true && offersArray}
          </div>

          <OfferWallPagination
            amount={Math.ceil(totalOffers / offersPerPage)}
            current={currentPage}
            next={() => {
              console.log('next page: ' + (currentPage + 1));
              setCurrentPage(currentPage + 1);
              scroll.scrollToTop();
            }}
            prev={() => {
              console.log('prev page: ' + (currentPage - 1));
              setCurrentPage(currentPage - 1);
              scroll.scrollToTop();
            }}
            goto={(id) => {
              console.log('go to page: ' + id);
              setCurrentPage(id);
              scroll.scrollToTop();
            }}
          />
        </article>
      </section>
    </Fragment>
  );
};
