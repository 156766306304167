import React, { useState } from 'react';

function SupportForm() {
    const [formData, setFormData] = useState({
        email: '',
        Name: '',
        Category: 'Issue Type',
        description: '',
    });

    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState({ visible: false, message: '', type: '' }); // New state for alerts

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Basic email validation
    const isValidEmail = email => /\S+@\S+\.\S+/.test(email);

    // Sanitize input to avoid XSS attacks
    const sanitizeInput = input => {
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#x27;',
            "/": '&#x2F;',
        };
        const reg = /[&<>"'/]/ig;
        return input.replace(reg, (match) => (map[match]));
    };

    // Validate form data
    const validateFormData = (data) => {
        let isValid = true;
        const newErrors = {};

        if (!isValidEmail(data.email)) {
            isValid = false;
            newErrors.email = 'Invalid email address';
        }

        // Add more validation checks as needed

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Client-side validation and sanitization logic here
        // Sanitize form data
        const sanitizedData = Object.keys(formData).reduce((acc, key) => {
            acc[key] = sanitizeInput(formData[key]);
            return acc;
        }, {});

        // Validate form data
        if (!validateFormData(sanitizedData)) {
            console.error('Validation failed');
            return; // Stop form submission
        }

        try {
            if (process.env.NODE_ENV === 'production') {
                const response = await fetch('/e/send', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(sanitizedData),
                });
    
                if (response.ok) {
                    //console.log('Email sent successfully');
                    // Handle success
                    setAlert({ visible: true, message: 'Email sent successfully', type: 'success' });
                } else {
                    //console.log('Failed to send email');
                    //console.log(sanitizedData);
                    // Handle failure
                    setAlert({ visible: true, message: 'Failed to send email. Please try again.', type: 'danger' });
                }
            } else if('development') {
                //console.log(sanitizedData);
                setAlert({ visible: true, message: 'Form submitted in development mode.', type: 'warning' });
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle error
            setAlert({ visible: true, message: 'An error occurred. Please try again later.', type: 'danger' });
        }
    };

    return (
        <div className='secondarySupportContent'>
            <h4>
                Support
            </h4>

            <hr />

            {/* Alert Message */}
            {alert.visible && (
                <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setAlert({ ...alert, visible: false })}></button>
                </div>
            )}            
            
            <form onSubmit={handleSubmit} className="container mt-4">
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="Name" className="form-label">Name</label>
                    <input
                        type="text"
                        className={`form-control ${errors.Name ? 'is-invalid' : ''}`}
                        id="Name"
                        name="Name"
                        value={formData.Name}
                        onChange={handleChange}
                        required
                    />
                    {errors.Name && <div className="invalid-feedback">{errors.Name}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="Category" className="form-label">Category</label>
                    <select
                        className="form-select"
                        id="Category"
                        name="Category"
                        value={formData.Category}
                        onChange={handleChange}
                        required
                    >
                        <option disabled>Support For</option>
                        <option value="Redeem-Payout">Redeem / Payout</option>
                        <option value="Account">Account</option>
                        <option value="OfferStatus">Offer Status</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Detailed Description</label>
                    <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        rows="3"
                        value={formData.description}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
}
export default SupportForm;
