import React from "react";
import styles from "./Features.module.css";
import Layout from "../../layouts/Layout/Layout";
import { CardButton } from "../../ui";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";

export default ({ data = [] }) => {
  const [state, actions] = useCustomState();

  const featuresList = data
    .map((item, index) => (
      <article key={index}>
        <CardButton
          to={"/offers"}
          btn_after="&#xf105;"
          btn_text="Explore Deals"
          btn_round
          animation="slide-right"
          padding
          btn_type="solid-footerBgColor-tw"
          btn_hoverType="solid-pink-tw"
          background={THEME.color}
        >
          <div className={styles.features}>
            <i className={ item.i }></i>
            {/* <img src={item.icon} alt="features_icon" /> */}
            <p className={styles.featuresTitle}>{item.title}</p>
            <p style={{ marginTop: '8px'}}>{item.text}</p>
          </div>
        </CardButton>
      </article>
    ))
    .slice(0, 3);
  
    return (
    <Layout
      classNameExtra="layout-features"
      // style={{
      //   height: '650px',
      //   //background: "url(" + state.data.header_bgs.confetti + ") center/cover",
      // }}
      col="3"
      padding
    >
      <div className="layout-header">
        <span className="subtitle"
              style={{lineHeight:'.8'}}
        >
          Key features
        </span>
        <h2>Check out our How To guides and see how we work.</h2>
        {/* <p>
        In this guide, we’ll get you up to speed on all things offers * How upmatic offers work * The different offer types * Where offers are found on upmatic * Turning points into free gift cards
        </p> */}
      </div>
      {featuresList}
      <div className={["layout-footer", styles.footer].join(" ")}>
        <span>
          <b style={{ color: THEME.color }}>Start Earning Now</b>
        </span>
        <span>Click and Claim Your Rewards!</span>
      </div>
    </Layout>
  );
};
