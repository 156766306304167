import { useState, useEffect } from "react";
import axios from "axios";

let listeners = [];

let state = {
  token: null,
  user: localStorage.getItem('user'),
  loginSuccess: null,
  modalClose: null,
  loggedIn: localStorage.getItem('user') != null,
  data: null,
  offerId: null,
  error: null,
  sidebar: false,
  modal: false,
  signup: false,
  video: false,
  loading: false,
  request: {
    modal: false,
    text: "",
  },
};

const actions = {
  fetch: async () => {
    try {
      const response = await axios.get(
        // Change with your database path
        "/api/data"
      );
      state = { ...state, data: response.data };
    } catch (err) {
      state = { ...state, error: err };
    }

    for (const listener of listeners) {
      listener(state);
    }
  },
  toogleSidebar: () => {
    state = { ...state, sidebar: !state.sidebar };

    for (const listener of listeners) {
      listener(state);
    }
  },
  toogleModal: (isModal = null) => {
    if(isModal == null){
      state = { ...state, modal: !state.modal };
    }else{
      state = { ...state, modal: isModal };
    }

    if(state.modalClose && (isModal == false || state.modal == false)){
      state.modalCloseCallback();
    }
    
    //alert("using Actions" + state.offerId);
    for (const listener of listeners) {
      listener(state);
    }
    //if (state.token != null) {
      //console.log("state.token");
      //console.log(state.token);
    //}
  },
  toogleSignup: () => {
    state = { ...state, signup: !state.signup };
    //alert("using Actions" + state.offerId);
    for (const listener of listeners) {
      listener(state);
    }
  },
  toogleRequest: (payload) => {
    state = {
      ...state,
      request: { modal: !state.request.modal, text: payload },
    };

    for (const listener of listeners) {
      listener(state);
    }
  },
  toogleVideo: () => {
    state = { ...state, video: !state.video };

    for (const listener of listeners) {
      listener(state);
    }
  },
  toogleLoading: (isLoading) => {
    state = {...state, loading: isLoading}

    for (const listener of listeners) {
      listener(state);
    }
  },
  updateLoggedIn: (isLoggedIn) => {
    state = {...state, loggedIn: isLoggedIn}

    for (const listener of listeners) {
      listener(state);
    }
  },
  updateUser: (user) => {
    state = {...state, user: user}

    if(user){
      state = {...state, loggedIn: true}
    }

    for (const listener of listeners) {
      listener(state);
    }
  },
  loginSuccessCallback: (callback) => {
    state = {...state, loginSuccess: callback}

    for (const listener of listeners) {
      listener(state);
    }
  },
  modalCloseCallback: (callback) => {
    state = {...state, modalClose: callback}

    for (const listener of listeners) {
      listener(state);
    }
  }
};

export const useCustomState = () => {
  const setState = useState(state)[1];

  useEffect(() => {
    listeners.push(setState);

    return () => {
      listeners = listeners.filter((li) => li !== setState);
    };
  }, [setState]);

  return [state, actions];
};
