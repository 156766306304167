import React from 'react'

const About = () => {
  return (
    <>
      <div className='secondaryAboutContent'>
        <h4>
            Upmatic Offer Rewards Your Trusted Source for Online Rewards
        </h4>

        <hr />

        <p>
        We know that putting your money and effort into online rewards programs can be a gamble.  That's why upmatic.io is dedicated to building a rewards and loyalty experience where you know you're getting real value for your time.  We help you make the most of your online activity, earning and saving real money while you do it.            
        </p>
        <p>
        The internet is full of rewards programs making big promises.  Unfortunately, many of them leave you with empty pockets and a frustrating experience.  We've all been let down by complicated rules, hidden fees, and rewards that never materialize.
        </p>
        <p>
          Upmatic.io is different. We believe online rewards should be:
          <ul>
            <li>
            Transparent: Our offers, rules, and rewards are straightforward and easy to understand.
            </li>
            <li>
            Reliable: We partner with trusted affiliates committed to delivering the goods.
            </li>
            <li>
            Worth Your Time: We curate worthwhile offers that provide real value for your effort.
            </li>
          </ul>
        </p>
        <p>
          Whether you're looking for cash rewards, discounts on your favorite brands, or exclusive experiences,  Upmatic.io helps you get the most out of your online activity.
        </p>

      </div>
    </>
  )
  }  
  export default About