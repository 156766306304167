import React, { useState } from 'react';
import { useCustomState } from './state/state';
//The problem is your code never alerts React that the token retrieval was successful. You’ll still need to set some state that will trigger a re-render when the data changes. Like most problems in React, there are multiple ways to solve it. One of the most elegant and reusable is to create a custom Hook.

// Creating a Custom Token Hook
export default function useToken() {
    const state = useCustomState()[0];
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        //console.log(tokenString)
        const userToken = tokenString;
        //console.log(userToken);

        state.token = userToken;

        return userToken
      };
    const [token, setToken] = useState(getToken());

    const saveToken = userToken  => {
        localStorage.setItem('token', userToken.token);
        //console.log(userToken);
        
        setToken(token);
      };

    return {
        setToken: saveToken,
        token
    }
}
