import React, { useEffect, useState, useRef } from 'react';
import styles from "../../widgets/Team/Team.module.css";
import Layout from "../../layouts/Layout/Layout";
import {
    Header,
    OffersWidget,
} from "../../widgets";
import { CardImage } from "../../ui";
import { Button, Link} from "../../elements";
import { getFeaturedOfferRewards, getOfferRewardWithPagination } from '../../../services/basicGet';
import { useCustomState } from "../../../state/state";
import "../../../index.css";

function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

export default ({ data = [], pageTitle="pageTitle" }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const state = useCustomState()[0];
    const [list, setList] = useState([]);

  // for useRef
  const mounted = useRef(true);

  // for API call to get Offers
  useEffect(() => {
    // for useRef
    mounted.current = true;
    
    // Start loading when the API call begins
    setLoading(true);
    // if list is already populated no need to load
    if (list != null && list.length) {
      //setLoading(false);
      return;
    }

    if(list != null && list.length) {
        return;
    }

    getFeaturedOfferRewards(1, 10, 
      function(response) {
        if (!response || response.errors || typeof response !== 'object' || Object.keys(response.data).length === 0) {
          throw new Error('Failed to fetch data'); // Explicitly throw an error if the response is not as expected
        }
        getOffersSuccessHandler(response.data);
      }, function(err){
        getOfferRewardWithPagination(1, 3, function(response){
          if(response?.data?.length > 0){
            getOffersSuccessHandler(response.data);
          }else if(response.length > 0){
            getOffersSuccessHandler(response);
          }else{
            getOffersSuccessHandler(state.data.offers);  
          }
        }, function(err){
          getOffersSuccessHandler(state.data.offers);
        });
    });

        // Cleanup function remains unchanged
        return () => mounted.current = false;

  }, []);

  function getOffersSuccessHandler(response){
    if (mounted.current) {
      setList(response); // Update state with the fetched data
      setLoading(false); // Data is loaded
  }
  }

  function getOffersErrorHandler(err){
    if (mounted.current) {
      setError(err); // Set error if the API call fails
      setLoading(false); // Ensure loading is set to false on error
      }
  }

  // for use by page's title
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

      // static data
      const teamList = data.map((item, index) => (
        <article key={index}>
          <CardImage image={item.creativeImage} label="Trending">
            <div className={styles.card}>
              <span>{item.title}</span>
              <h3>{truncateString(item.offerDescription, 30)}</h3>
              { item.status !== "none" ?              
              <Button 
                click={() => {
                  window.open(item.trackingLink, '_blank').focus();
                }}
                type="solid-footerBgColor-tw"
                hoverType="solid-color-tw"
                round
              >
                Earn {item.amount}
              </Button>
              : 
              <Button 
                to={`/offer/o/${item.id}`}
                type="solid-footerBgColor-tw"
                hoverType="solid-color-tw"
                round
              >
                Click to Earn
              </Button>
            }
            </div>
          </CardImage>
        </article>
      )).slice(0,3);

      // dynamic data
      //console.log(list);
      const dynamicList = list.map((item, index) => (
        <article key={index}>
          <CardImage image={item.creativeImage} label="Trending">
            <div className={styles.card}>
              <span>{item.title}</span>
              <h3>{truncateString(item.offerDescription, 30)}</h3>
              { item.status !== "none" ?              
              <Button 
                click={() => {
                  window.open(item.trackingLink, '_blank').focus();
                }}
                type="solid-footerBgColor-tw"
                hoverType="solid-color-tw"
                round
              >
                Earn {item.amount}
              </Button>
              : 
              <Button 
                to={`/offer/o/${item.id}`}
                type="solid-footerBgColor-tw"
                hoverType="solid-color-tw"
                round
              >
                Click to Earn
              </Button>
            }
            </div>
          </CardImage>
        </article>
      )).slice(0,3);


    // LayoutTeamHeader
    const LayoutTeamHeader = () => (
      <div className="layout-team-header">
      {/* <span className="subtitle" style={{ color: THEME.color }}>
        Team members
      </span> */}
      <h1 style={{ color: "#fff", fontSize: '36px' }}>
        Hey, you should not be back here.
      </h1>
      <p style={{ color: "#fff", fontSize: '20px' }}>
        Since you are here check out the featured offers
      </p>
    </div>
    );

    return (
      <Layout
        classNameExtra="layout-team"
        mode="dark"  
        col="3"
        padding
        >
          { error ? (
            <>
              <LayoutTeamHeader />
              { teamList }
            </>  
          ) : (
            <>
              <LayoutTeamHeader />
              { dynamicList }
              </>
          )
          }
      </Layout>
    ); 
};