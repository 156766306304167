import React, { useEffect, useState } from 'react';
import { getTrackingLink } from '../../services/basicGet';
import PropTypes from 'prop-types';
import { login } from '../../services/authenticate';

import './Login.css';
import { useCustomState } from '../../state/state';

// turn to service
async function loginUser(credentials) {
    return fetch('https://api.upmatic.io/Authenticate/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
        .then(result => {
            return result;
        })
}

export default function LoginSimple({ setToken, strOfferId }) {

  useEffect((strOfferId) => {
    //console.log('login: ' + setToken);
    //console.log('login: ' + strOfferId);

    //window.alert(strOfferId)
  }, [])

  //console.log(setToken)
  //console.log(strOfferId);

    // React_Forms_Tutorials
    const initialValues = { email: "", password: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [state, actions] = useCustomState();
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
      //console.log(formValues);
    };
  
    const handleSubmit2 = async (e) => {
      e.preventDefault();
      setFormErrors(validate(formValues));

      login(formValues.email, formValues.password, (response) => {
        console.log('----------- success --------------');
        console.log(token);
        const token = response?.token;
        actions.updateUser(formValues.email);
        actions.updateLoggedIn(true);
        localStorage.setItem('user', formValues.email);
        //state.user = formValues.email;
        console.log('user: ' + state.user);
        setFormValues(initialValues);
          if(setToken != null){
            localStorage.setItem('token', token.token);  
            setToken(token);
              window.alert('did it run: ' + strOfferId);
              // call to get tracking link
              var strTrackingLink = getTrackingLink(strOfferId);   
              
              if(strTrackingLink != null){
                strTrackingLink.then((res) => {
                  //console.log(res);
                  var strRes = res;
                  //console.log(strRes);
                  //console.log(strRes.link);
                  //var test = 'https://leadid.net'
                  window.location = strRes.link;
                  //window.location = test;
                })
                .catch((err) => {
                  //console.log(err);
                });              
              }
          }
        setIsSubmit(true);
      }, function(err){
        if(err?.status == 'Failed' && err?.message != null){
          setFormErrors({email: err.message});
        }
      });
    };
  
    useEffect(() => {
      //console.log(formErrors);
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        //console.log(formValues);
      }
    }, [formErrors]);
    
    const validate = (values) => {
      const errors = {};
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!values.email) {
        errors.email = "Email is required!";
      } else if (!regex.test(values.email)) {
        errors.email = "This is not a valid email format!";
      }
      if (!values.password) {
        errors.password = "Password is required";
      } else if (values.password.length < 4) {
        errors.password = "Password must be more than 4 characters";
      } else if (values.password.length > 60) {
        errors.password = "Password cannot exceed more than 10 characters";
      }
      return errors;
    };

  return(
    <div className='container'>
        {/* {Object.keys(formErrors).length === 0 && isSubmit ? (
            <div className="ui message success">Signed in successfully</div>
        ) : (<>
            <pre>{JSON.stringify(formValues, undefined, 2)}</pre>
            <pre>{JSON.stringify(formValues.email)}</pre>
            <pre>{JSON.stringify(formValues.password)}</pre>
            </>
        )} */}

        <form onSubmit={ handleSubmit2 }>
            <h1>Login Form</h1>
            <label>
                <p>Email</p>
                <p>{formErrors.email}</p>
                <input 
                    type="text"
                    name="email"
                    placeholder="name@example.com"
                    value={formValues.email}
                    //onChange={ e => setUserName(e.target.value)}
                    onChange={handleChange}
                />
            </label>
            <br />
            <label>
                <p>Password</p>
                <p>{formErrors.password}</p>
                <input 
                    type="password"
                    name="password"
                    placeholder="Password"
                    //onChange={ e => setPassword(e.target.value)}
                    value={formValues.password}
                    onChange={handleChange}
                />
            </label>
            <div>
                <p className="forgot" align="center">
                    <a href="/forgot">Forgot Password? </a>
                </p>
            </div> 
            <div>
                <button className="btn btn-primary mb-4" type="submit">Submit</button>
            </div>
        </form>

</div>
  )
}

LoginSimple.prototype = {
    setToken: PropTypes.func.isRequired
}