import React, { useState, useEffect, useRef } from "react";
import styles from "./Lander.module.css";
import styles2 from "../../../components/pages/OfferWall/components/OfferWallDetailsModal/OfferWallDetailsModal.module.css";
import { useCustomState } from "../../../state/state";
import { Layout } from "../../layouts";
import { Button, Link } from "../../elements";
import THEME from "../../../state/theme";
import { getOfferRewards, getOfferTrackingLink } from "../../../services/basicGet";
import "./offer.css";

const OfferChild = ({ offerId='offerId' }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [list, setList] = useState([]);
  const [state, actions] = useCustomState();
  const [noOffer, setNoOffer] = useState();
  const coinAmount = 100;

  console.log(offerId);

  const SkeletonCard = () => {
    return (
      <div className="card">
        <div className="card-body">
          <div className="skeleton skeleton-title"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-button"></div>
        </div>
      </div>
    );
  };  

  const data = [
    {"itemTitle": "Click to Start the Deal", "itemDesc": "Select a deal that interests you and click to begin. Follow the instructions provided to get started. Each deal is designed to be easy to follow and rewarding."},
    {"itemTitle": "Complete the Requirements", "itemDesc": "Every deal has specific requirements to meet. Track your progress and reach milestones to earn points. Stay focused and complete the tasks to maximize your rewards."},
    {"itemTitle": "Be Rewarded", "itemDesc": "Once you successfully complete a deal, you will be rewarded with points. These points can be accumulated and redeemed for various rewards. Enjoy the benefits of your efforts immediately."},
    {"itemTitle": "Click Rewards", "itemDesc": "Visit the Rewards page to redeem your points and cash out your earnings. It's as easy as shopping online. Explore the available rewards and enjoy your earnings."},
  ];

  // for useRef
  const mounted = useRef(true);

  useEffect(() => {
    //console.log(state.data.offers);
    // for useRef
    mounted.current = true;

    setLoading(true)
    // if list is already populated no need to load
    if (list != null && list.length) {
      //setLoading(false);
      return;
    }
    if(list != null && list.length) {
        return;
    }    

    getOfferRewards(offerId, 
      function(response) {
        if (!response || response.error || typeof response !== 'object' || Object.keys(response).length === 0) {
          throw new Error('Failed to fetch data'); // Explicitly throw an error if the response is not as expected
        }
        if (mounted.current) {
            let tempArray = [];
            tempArray.push(response.data);
            console.log(tempArray);
            console.log(response);
            if(response.data == null && response.data.succeeded == true){
              // set error because no offer found
              setError(true);
            }
            else {
              setList(tempArray);
            }
          //setList(response); // Update state with the fetched data
          setLoading(false); // Data is loaded
        }
      }, function(err){
      if (mounted.current) {
        setError(err); // Set error if the API call fails
        setLoading(false); // Ensure loading is set to false on error
      }
    });

    // Cleanup function remains unchanged
    return () => mounted.current = false;
  }, []);

  const OfferHeader = () => (
    <div className={styles.header}>
      <span className={[styles.content, styles.shadowText].join(' ')}>
        {
          error ? (<div>Offer Not Found</div>) : (<div>Today's Opportunities</div>)
        }
        
      </span>
    </div>
  );

  const OfferFooter = () => (
    <div className={[styles.footer, styles.shadowText].join(' ')}>No Credit Card Needed, anyone can Apply!</div>
  );

  const HowToStepsDynamic = ({ items }) => {
    console.log(items);
    return (
      <>
        <div className="HeaderHowToSteps">
          How it Works
        </div>
        <div className="MiddleHowToSteps-div">          
          <ul className="numbered-itemsLid">     
            {
              items.map((item, index) => (
                <li key={ index }>
                  <div className="flow" style={{ marginTop: "1em"}}>
                    <h3 className="numbered-items__titleLid fs-600 fw-bold"> { item.itemTitle } </h3>
                    <p className="numbered-items__bodyLid">
                      { item.itemDesc }
                    </p>
                  </div>
                </li>
              ))
            }  
          </ul>
        </div>
        <div className="FooterHowToSteps">
          {/* Footer */}
        </div>
      </>
    );
  };

  const HowToStepsDynamicTyny = ({ listItem }) => {
    console.log(listItem);
    return (
      <>
        {/* <div className="HeaderHowToSteps">
          How it Works
        </div> */}
        <div className="MiddleHowToSteps-div">          
          <ul className="numbered-itemsLidTiny">
            
            {
              listItem.events.map((event, index) => (
                <li key={ index }>
                  <div className="flow" style={{ marginTop: ".5em"}}>
                      <h3 className="numbered-items__titleLidTiny fs-500 fw-bold"> { 
                        event.name
                      }
                    </h3>
                  </div>
                </li>
              ))
            }  
          </ul>
        </div>
        {/* <div className="FooterHowToSteps"> */}
          {/* Footer */}
        {/* </div> */}
      </>
    );
  };  

  // static data
  const offerItemStatic = state.data.offers.map((item, index) => (
    <div className={styles.middle} key={index}>{item.title}</div>
  )).slice(0,1);

  // no offer found
  const offerNotFound = (
    <div className={styles.middle}>
      <div className="wrapperNoOffer">
        <div className="contentNoOffer">
          <i className="las la-exclamation-circle"></i>
          <p>No Offer Found</p>
          <div className="button-wrapper-no-offer">
            <a href="/offers" className="btn btn-primary">Latest Deals</a>
          </div>
        </div>
      </div>
    </div>
  );

  // if loading is true
  if (loading) return (
    // <div
    //   className={styles.parallax}
    //   style={{
    //     backgroundImage: "url(" + state.data.header_bgs.CircularVectorSmartObject + ")",
    //   }}
    //   >
      <Layout col="1">
        <div className={styles.intro}>
          <h1>
            Loading ...
          </h1>
        </div>
      </Layout>
    // </div>
  );

  // OUTERBOX
  const ThreeDivLayout = ({ listItem }) => {
    const outerBoxStyle = {
        maxWidth: '100%',
        display: 'flex',

    };

    const innerBoxStyle = {
        flex: 1,
        padding: '2px',
    };

    const leftBoxStyle = {
        width: '50%',
        ...innerBoxStyle,
    };

    const rightBoxStyle = {
        width: '50%',
        ...innerBoxStyle,
    };

    const listStyle = {
      fontSize: 'small',
      padding: '2px',
      listStyle: 'circle',
      ...innerBoxStyle,
    }
    return (
      <div style={outerBoxStyle}>
          <div style={leftBoxStyle}>
          <h3>Events</h3>
                  <ul>
                    {
                      listItem.events ? (
                        listItem.events.length > 0 ? (
                          listItem.events.map(event => (
                            <li style={listStyle} key={event.id}>{event.name}</li>
                          ))
                        ) : (
                          <div>No Events</div>
                        )
                      ) : (
                        <div>No Events</div>
                      )
                    }
                  </ul>
          </div>
          <div style={rightBoxStyle}>
          <h3>Requirements</h3>
          <p>{listItem.rewardDescription}</p>
          </div>
      </div>
    );
};

  // SINGLE OFFER MAP
  const offerItemApi = list.map((list, index) => (
    <div className={styles.middle} key={index}>
        <div style={{ width: "100%"}} className="auth-wrapper-offer-child">
          <div className="auth-inner-offer-child">
            <div>
              <span>
                <p style={{fontSize:"2em", fontFamily: "Poppins"}} >{`${list.title}`}</p>
                <figure style={{background: THEME.color}}></figure>
              </span>

              <div className={styles2.container}>
                <div className={styles2.thumbnail}>
                  <img src={list.creativeImage} />
                </div>
                  <div className={styles2.details}>
                    <div className={styles2.detailRow}>  
                      <figcaption className={styles2.detailKey}>    
                        <p style={{textAlign:'end'}}>
                          <span>Amount: </span>
                        </p>
                      </figcaption>
                      <div className={styles2.detailValue}>  
                        {
                          list.amount <= coinAmount &&
                          <>
                            <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                            <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                            <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                            <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                          </>
                        }
                        {
                          list.amount > coinAmount && list.amount <= coinAmount * 2 &&
                          <>
                            <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                            <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                            <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                            <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                          </>
                        }
                        {
                          list.amount > coinAmount * 2 && list.amount <= coinAmount * 3 &&
                          <>
                            <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                            <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                            <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                            <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                          </>
                        }
                        {
                          list.amount > coinAmount * 3 &&
                          <>
                            <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                            <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                            <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                            <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                          </>
                        }
                      </div>
                    </div>

                    <div className={styles2.detailRow}>  
                      <figcaption className={styles2.detailKey}>        
                          <p style={{textTransform: 'capitalize', textAlign:'end'}}><span>Requirement: </span></p>
                      </figcaption>
                      <figcaption className={styles2.detailValue}>           
                          <p style={{textTransform: 'capitalize'}}><span>{list.requirementType}</span></p>
                      </figcaption>
                    </div>
                      {
                        list.categories?.length > 0 && 
                        <div className={styles2.detailRow}>  
                        <figcaption className={styles2.detailKey}>       
                              <p style={{textTransform: 'capitalize', textAlign:'end'}}><span>Categories: </span></p>
                          </figcaption>

                          <div className={styles2.detailValue}>
                            <figcaption>       
                              <p> 
                                {list.categories.map((cat, index) => {
                                    return(
                                      <>
                                        <span>{cat.name}</span>{index + 1 < list.categories.length && <span>, </span>}
                                      </>
                                    )
                                  })
                                }
                              </p>
                            </figcaption>
                          </div>
                        </div>
                      }
                    
                    <div className={styles2.detailRow}>  
                      <figcaption className={styles2.detailKey}>          
                          <p style={{textTransform: 'capitalize', textAlign:'end'}}><span>Device: </span></p>
                      </figcaption>
                      <figcaption className={styles2.detailValue}>      
                      {list.deviceType == 'ios' && 
                        <>
                          <i className="lab la-apple" style={{color: THEME.pinkBtn}}/>
                          <i className="las la-mobile" style={{color: THEME.pinkBtn}}/>
                        </>
                      }    

                      { list.deviceType == 'android' &&
                        <>
                          <i className="lab la-android" style={{color: THEME.pinkBtn}}/>
                          <i className="las la-mobile" style={{color: THEME.pinkBtn}}/>
                        </>
                      } 

                      {list.deviceType == 'web' && 
                        <i class="las la-desktop" style={{color: THEME.pinkBtn}}></i>
                      }   
                    </figcaption>
                  </div>                            
                </div>
              </div>
        
              <div style={{ width: "100%", maxWidth: "none"}} className={styles2.text}>
                <p>{list.offerDescription}</p>

                <ThreeDivLayout  listItem={list} />
                {/* <HowToStepsDynamic  items={data} /> */}

                <Button click={() => {
                  if(list.trackingLink == null){
                    actions.toogleModal(true);
                    //setOfferDetails(null);
                    actions.loginSuccessCallback(function(){
                      getOfferTrackingLink(list.id, function(strRes){
                        if (strRes.link != null) {
                            state.offerId = null;
                            console.log(strRes.link);
                            actions.toogleModal(false);
                            window.open(strRes.link);
                        }
                      });
                    });
                  }else{
                    window.open(list.trackingLink)
                  }
                  }} type="solid-pink-tw" hoverType="solid-color-tw" round>
                  {list.amount} Points
                </Button>

              </div>
            </div>   
          </div>
        </div>
      
        {/* was a card right half */}
        <div style={{width: "100%"}}>
          <div style={{ padding: "10px" }}>
          <HowToStepsDynamic items={data} />
        </div>
      </div>
    </div>
  )).slice(0,1);

  return (
    <Layout col="1" classNameExtra={styles.headerBackgroundLeftSide}>
      <div className={styles.intro}>
        <div className={styles.container}>
          <OfferHeader />
            {
                error ? (
                  offerNotFound
                ) : (
                  offerItemApi
                )
            }
          {/* <OfferFooter /> */}
        </div>
      </div>
    </Layout>
  // </div>
  )
}

export default OfferChild;