import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useCustomState } from "../../../state/state";
import styles from "./VideoHeader.module.css";
import THEME from "../../../state/theme";
import { Layout } from "../../layouts";
import { Link } from "../../elements";

export default () => {
  const state = useCustomState()[0];
  const navigate = useNavigate();
  const [isPlay, setPlay] = useState(false);
  const ref = useRef();

  // const playToogle = () => {
  //   isPlay ? ref.current.pause() : ref.current.play();
  //   setPlay(!isPlay);
  // };

  const followLink = () => {
    // event Google Analytics

    if(window.gtag){
      window.gtag('event', 'homePage_followLink_clicked', {
        'app_name': 'upmatic.io',
        'screen_name': 'HomePage_Hero_Section'
      });  
    }
    
    navigate('/offers');
  };

  return (<>
    <Layout classNameExtra={styles.headerBackground}>
    <div className={styles.textWrapperHeroSection}>
      {/* <video ref={ref} src={state.data.video.header} autoPlay loop muted type="video/mp4" /> */}
      <div className={ [styles.columnHero, styles.textContainerHeroSection].join(" ") }>
            <h1 className={styles.heroFontFamily}>Get Rewarded with Upmatic Points</h1>
              <span className={styles.heroFontFamily}>
                Exchange Upmatic Points for Gift Cards or Crypto*
              </span>
      </div>
      <div className={ [styles.columnHero, styles.columnCenterHero ].join(" ")} >
      {/* <span>Deal for You</span> */}
        <button
          className={isPlay ? styles.pause : styles.play}
          style={{ borderColor: THEME.YellowIsh }}
          onClick={followLink}
          hover={ THEME.NavyIsh}
        >

          <div className={styles.icon_wrapper}>
            <span
              style={{ background: THEME.YellowIsh }}
              className={[styles.bar, styles.bar_1].join(" ")}
            />
            <span
              style={{ background: THEME.YellowIsh }}
              className={[styles.bar, styles.bar_2].join(" ")}
            />

          </div>
        </button>
        {/* <a
                    className='btn btn-primary'
                    type='button'
                    tabIndex='10'
                    href="/offers"
                    alt="Link to deals">
                      Deals for You
                  </a> */}
      </div>
      <div className={ [styles.columnHero, styles.thirdColumn].join(" ")}>

      </div>
    </div>
    <div className={ [styles.fullWidthRow, styles.heroFontFamily].join(" ") }>
        <p>* Upon fulfilling the requirements of the Upmatic Reward program</p>
      </div>
      </Layout>
  </>);
};
