import React from 'react'
import { useCustomState } from "../../state/state";
import "../../index.css";
import THEME from "../../state/theme";

export default function LoggedOutUser(props) {
    const actions = useCustomState()[1];

    return (
        <>
            <a
                // style={{ color: THEME.purple }}              
                href="#"
                className="btn primary"
                onClick={() => actions.toogleModal(true)}
            >
                Log In
            </a>
        </>
    )
}