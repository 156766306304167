import React from "react";
import styles from "./Logo.module.css";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";


export default ({ dark }) => {
  const [state, action] = useCustomState();

  return (<>
  <figure
    className={[styles.logo, dark ? styles.dark : styles.light].join(" ")}
    
  >
    {/* <i className="las la-industry" /> */}
    <img
      style={{ width: '15%'}}
      src={ state.data.upmatic_logos.upmaticLargeLogo1024 }
      alt="Upmatic logo" />
    <span
      style={{ color: '#FFF', marginLeft: '1rem' }
    }>
      Upmatic Rewards
    </span>
  </figure>
  </>
  );
};
