import React, { useEffect, useState } from "react";
import { confirmEmail } from "../../services/authenticate";
import {Alert} from 'react-bootstrap';
import '../../App.css';
import { useCustomState } from "../../state/state";

export default function({ pageTitle="pageTitle" }){
    const [errorMessage, setErrorMessage] = useState();
    const [state, actions] = useCustomState();

    useEffect(() => {
        let searchParams = new URLSearchParams(document.location.search.replace('?', ''));
        //(searchParams);
        var data = {
            email: searchParams.get('email'),
            code: searchParams.get('code')
        }
       // console.log(data);
        actions.toogleLoading(true);
        confirmEmail(data, 
        function(res){
            //console.log(res);
            //setSuccessMessage('Your email is confirmed.');
            actions.toogleLoading(false);
            window.location = '/';
        }
        , function(errors){
            if(errors != null){
                var errMess = '';
                for(var i = 0; i < errors.length; i++){
                    errMess += errors[i] + ' ';
                }
                setErrorMessage(errMess);
            }
            actions.toogleLoading(false);
        });
    }, []);

    // for use by page's title
    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);


    return(<>
        {/* <div className="auth-wrapper">
          <div className="auth-inner"> */}
          <div style={{marginTop: "25px", display:'flex', justifyContent:'center', fontSize:'2rem'}}>
            {
                errorMessage != null && <Alert key='danger' variant='danger'>
                    {errorMessage} Please confirm your email again.
                </Alert>
            }
        </div>
            {/* </div>
        </div> */}
    </>);
}