import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
import RadioGroup from 'react-bootstrap';
import styles from './OfferWallDetailsModal.module.css';
// import { Backdrop, ContactForm } from "..";
import { Backdrop } from '../../../../ui';
import { CardImage } from "../../../../ui";
import THEME from "../../../../../state/theme";
import { Button } from '../../../../elements';
import { useCustomState } from '../../../../../state/state';
import useToken from '../../../../../useToken';
import {getOfferRewards} from '../../../../../services/basicGet';

export default ({
  offerDetails,
  setOfferDetails
}) => {

  useEffect(() => {
    console.log(offerDetails);
  }, [offerDetails]);

  const {token, setToken} = useToken();
  const[state, actions] = useCustomState();
  const coinAmount = 100;

  return (
    <div className={[styles.wrapper, offerDetails && styles.show].join(' ')}>
      <div className={styles.modal} >
        <i
          onClick={() => {
            setOfferDetails(null);
            actions.toogleModal(false)
          }}
          className="las la-times"
          style={{color:'#ffffff'}}
        />
        <div className="auth-wrapper">
          <div className={[styles.modalmax, "auth-inner"].join(" ")}>


            <div>
              <span>
                <p>{offerDetails.title}</p>
                <figure style={{background: THEME.color}}></figure>
              </span>

              <div className={styles.container}>
                <div className={styles.thumbnail}>
                  <img src={offerDetails.creativeImage} />
                </div>
                <div className={styles.details}>

                  <div className={styles.detailRow}>  
                  <figcaption className={styles.detailKey}>    
                      <p style={{textAlign:'end'}}>
                        <span>Amount: </span>
                      </p>
                    </figcaption>
                    <div className={styles.detailValue}>  

                      {
                        offerDetails.amount <= coinAmount &&
                        <>
                          <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                          <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                          <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                          <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                        </>
                      }
                      {
                        offerDetails.amount > coinAmount && offerDetails.amount <= coinAmount * 2 &&
                        <>
                          <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                          <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                          <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                          <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                        </>
                      }
                      {
                        offerDetails.amount > coinAmount * 2 && offerDetails.amount <= coinAmount * 3 &&
                        <>
                          <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                          <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                          <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                          <i className="las la-coins" style={{color: THEME.greyBG}}></i>
                        </>
                      }
                      {
                        offerDetails.amount > coinAmount * 3 &&
                        <>
                          <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                          <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                          <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                          <i className="las la-coins" style={{color: THEME.pinkBtn}}></i>
                        </>
                      }
                    </div>
                  </div>

                  <div className={styles.detailRow}>  
                    <figcaption className={styles.detailKey}>        
                        <p style={{textTransform: 'capitalize', textAlign:'end'}}><span>Requirement: </span></p>
                    </figcaption>
                    <figcaption className={styles.detailValue}>           
                        <p style={{textTransform: 'capitalize'}}><span>{offerDetails.requirementType}</span></p>
                    </figcaption>
                  </div>
               
                  {
                    offerDetails.categories?.length > 0 && 
                    <div className={styles.detailRow}>  
                    <figcaption className={styles.detailKey}>       
                          <p style={{textTransform: 'capitalize', textAlign:'end'}}><span>Categories: </span></p>
                      </figcaption>

                      <div className={styles.detailValue}>
                        <figcaption>       
                          <p> 
                            {offerDetails.categories.map((cat, index) => {
                                return(
                                  <>
                                    <span>{cat.name}</span>{index + 1 < offerDetails.categories.length && <span>, </span>}
                                  </>
                                )
                              })
                            }
                          </p>
                        </figcaption>
                      </div>
                    </div>
                  }
                  
                  <div className={styles.detailRow}>  
                  <figcaption className={styles.detailKey}>          
                        <p style={{textTransform: 'capitalize', textAlign:'end'}}><span>Device: </span></p>
                    </figcaption>
                    <figcaption className={styles.detailValue}>      
                  {offerDetails.deviceType == 'ios' && 
                    <>
                      <i className="lab la-apple" style={{color: THEME.pinkBtn}}/>
                      {/* <i className="las la-mobile" style={{color: THEME.pinkBtn}}/> */}
                    </>
                  }    

                  { offerDetails.deviceType == 'android' &&
                    <>
                      <i className="lab la-android" style={{color: THEME.pinkBtn}}/>
                      {/* <i className="las la-mobile" style={{color: THEME.pinkBtn}}/> */}
                    </>
                  } 

                  {offerDetails.deviceType == 'web' && 
                    <i className="las la-desktop" style={{color: THEME.pinkBtn}}></i>
                  }   
                  </figcaption>
</div>
                            
                </div>

              </div>
        
              <div className={styles.text}>
                <p>{offerDetails.offerDescription}</p>

                <p>{offerDetails.rewardDescription}</p>
              </div>

              <div className={styles.btn}>

              {
                offerDetails.status != "converted" && offerDetails.status != "redeemed" &&
                <Button click={() => {
                  if(offerDetails.trackingLink == null){
                    actions.toogleModal(true);
                    setOfferDetails(null);
                    actions.loginSuccessCallback(function(){
                      //var strTrackingLink = getTrackingLink(offerDetails.id);
                      getOfferRewards(offerDetails.id, function(response){
                        let offer;
                        if(response.data != null){
                          offer = response.data;
                        }else{
                          offer = response;
                        }
                        if (offer.trackingLink != null) {
                          //state.offerId = null;
                          if(offer.status != "converted" && offer.status != "redeemed"){
                            offer.status = 'clicked';
                            window.open(offer.trackingLink);
                            actions.toogleModal(false);
                          }
                          setOfferDetails(offer);
                        }
                      }, function(err){
                      });
                    });
                  }else{
                    setOfferDetails({...offerDetails, status: 'clicked'});
                    window.open(offerDetails.trackingLink);
                  }
                  }} type="solid-pink-tw" hoverType="solid-color-tw" round>
                  Click to Earn {offerDetails.amount} Points
                </Button>
              }

                {
                offerDetails.trackingLink != null && (offerDetails.status == "converted" || offerDetails.status == "redeemed") &&
                <Button type="solid-gray-tb" round disabled={true}>
                  Earned {offerDetails.amount} Points
                </Button>
              }

              </div>
            </div>

           
          </div>
        </div>
      </div>
      <Backdrop />
    </div>
  );
};
