import React, { useState, useEffect, useCallback, Fragment } from 'react';
import styles from './Secondary.module.css';
import { Header, Sidebar, Footer } from '../../../components/layouts';
import { useCustomState } from '../../../state/state';
import SecLeftSideMenu from './SecLeftSideMenu';
import {
  About,
  Faqs,
  Support,
  Confirm,
  ConfirmedSuccess,
} from '../Secondary';

export default ({ sidebar = 'left', layout = 'grid', title = 'title', pageTitle='pageTitle' }) => {
  const state = useCustomState()[0];
  // Initialize currentView with title. Transform title to match component keys if necessary.
  const [currentView, setCurrentView] = useState(title);

  let menuItems = [
                  {id:1,title:"About"},
                  {id:2,title:"FAQ"},
                  {id:3,title:"Support"}
                ]

  // Object mapping approach for component selection
  const componentMapping = {
    'About': About,
    'FAQ': Faqs,
    // Assuming 'Contact' is a typo and you meant 'HowItWorks' or have a separate 'Contact' component

    'Support': Support,
    'Confirm': Confirm,
    'ConfirmedSuccess': ConfirmedSuccess,
  };
  
    // Update currentView if title prop changes
    useEffect(() => {
      setCurrentView(title);
    }, [title]);

    // for page's title element
    useEffect(() => {
      document.title = pageTitle;
    }, [pageTitle]);
  
    const SelectedComponent = componentMapping[currentView] || About; // Fallback to About if no match
  

  return (
    <Fragment>
      {/* <Header img={state.data.header_bgs.blog}>{pageTitle}</Header> */}
      <div className={styles.outerWrapper}>
      <header className={styles.secHeader}>
        <h1>{ title }</h1>
      </header>
      <section
        // style={{ backgroundColor: '#00CC00'}}
        className={[
          styles.wrapper,
          sidebar === 'left'
            ? styles.with_sidebar + ' ' + styles.left
            : sidebar === 'right'
            ? styles.with_sidebar + ' ' + styles.right
            : null,
        ].join(' ')}
      >
        <aside 
            className={styles.sidebar}>
          <div>
            <SecLeftSideMenu data={menuItems} />
          </div>
        </aside>

        <article>
          <div
            className={["col-sm-9" ,
              layout === 'list'
                ? styles.grid
                : layout === 'list'
                ? styles.list
                : null
            ].join(" ")}
          >
            {/* Render the selected component based on currentView */}
            <SelectedComponent />
            
          </div>  
        </article>
      </section>
      {/* <Footer /> */}
      </div>
    </Fragment>    
  );
};