import React, { useState } from "react";
import emailjs from "emailjs-com";
import { getTrackingLink } from '../../../services/basicGet';
import styles from "./LoginForm.module.css";
import { CardButton } from "../../ui";
import { Spinner } from "../../elements";
import useToken from '../../../useToken';
import { useCustomState } from "../../../state/state";
  
async function loginUser(credentials) {
  return fetch('https://api.upmatic.io/Authenticate/login', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
  })
      .then(data => data.json())
      .then(result => {
          return result;
      })
}

export default ({ style, strOfferId }) => {
  const [state, actions] = useCustomState();
  const {token, setToken} = useToken();
  const [sending, setSending] = useState(false);
  const [status, setStatus] = useState("");
  const [error, setError] = useState(false);

  //const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  //const [phone, setPhone] = useState("");
  //const [message, setMessage] = useState("");
  const [preferedCheck, setPreferedCheck] = useState(false);

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setStatus("Incorrect email address");
      setError(true);
      return;
    }

    if (password === "") {
      setStatus("Please enter your password");
      setError(true);
      return;
    }

    const token = await loginUser({
        email,
        password
    });
    if(setToken != null){
        setToken(token);
        
        if(state.offerId != null){
          //console.log(state.offerId);
          var strTrackingLink = getTrackingLink(state.offerId);   
            
          if(strTrackingLink != null){
            strTrackingLink.then((res) => {
              //console.log(res);
              var strRes = res;
              //console.log(strRes);
              //console.log(strRes.link);
              //var test = 'https://leadid.net'
              window.location = strRes.link;
              //window.location = test;
            })
            .catch((err) => {
              //console.log(err);
            });              
          }    
        }
        else {
          window.location.reload(false);
        }
    }

    setSending(true);

    resetForm();
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
  };

  const loading = sending && (
    <div className={styles.loading}>
      <Spinner />
    </div>
  );

  return (
    <form style={style} className={styles.wrapper}>
      {loading}
      <CardButton
        click={onSubmit}
        btn_after="&#xf107;"
        btn_text="Login"
        btn_type="solid-color-tb"
        btn_hoverType="solid-gray-tb"
        btn_align="stretch"
        padding
      >
        <div className={styles.contact_form}>
          <span className={[styles.status, error && styles.error].join(" ")}>
            {status}
          </span>
          <h4>Login Form:</h4>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email address *"
          />
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
          />
          {/* <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="10"
            placeholder="Enter your message"
          /> */}
          {/* <label className={styles.checkbox_group}>
            <p>Email prefered way to contact</p>
            <input
              onChange={() => setPreferedCheck(!preferedCheck)}
              type="checkbox"
              checked={preferedCheck}
            />
            <span className={styles.checkmark} />
          </label> */}
        </div>
      </CardButton>
    </form>
  );
};
