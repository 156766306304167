import React, { useState, useRef, useEffect } from "react";
import '../../../App.css';
import styles from "./Team.module.css";
import Layout from "../../layouts/Layout/Layout";
import { Backdrop, CardImage } from "../../ui";
import { Button, Link, Spinner } from "../../elements";
import THEME from "../../../state/theme";
import { useCustomState } from "../../../state/state";
import { headerGet, getOfferRewards, getFeaturedOfferRewards, getOfferRewardWithPagination } from '../../../services/basicGet';
import { useNavigate } from "react-router";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";

function truncateString(str, num) {
  //console.log(str);
  //console.log(num);
  if (str.length > num) {
    //console.log(str.slice(0, num));
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export default ({ data = [] }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, actions] = useCustomState();
  const [list, setList] = useState([]);
  const navigate = useNavigate();

  // for useRef
  const mounted = useRef(true);  

  // for API call to get Offers
  useEffect(() => {
    // for useRef
    mounted.current = true;
    setList(null);
    
    // Start loading when the API call begins
    setLoading(true);
    // if list is already populated no need to load
    // if (list != null && list.length) {
    //   //setLoading(false);
    //   return;
    // }
    getFeaturedOfferRewards(1, 10, 
          function(response) {
            if (!response || response.errors || typeof response !== 'object' || Object.keys(response.data).length === 0) {
              throw new Error('Failed to fetch data'); // Explicitly throw an error if the response is not as expected
            }
            getOffersSuccessHandler(response.data);
          }, function(err){
            getOfferRewardWithPagination(1, 3, function(response){
              if(response?.data?.length > 0){
                getOffersSuccessHandler(response.data);
              }else if(response.length > 0){
                getOffersSuccessHandler(response);
              }else{
                getOffersSuccessHandler(state.data.offers);  
              }
            }, function(err){
              getOffersSuccessHandler(state.data.offers);
            });
        });

            // Cleanup function remains unchanged
            return () => mounted.current = false;
  }, [state.user]); 

  function getOffersSuccessHandler(response){
    if (mounted.current) {
      setList(response); // Update state with the fetched data
      setLoading(false); // Data is loaded
    }
  }

  function getOffersErrorHandler(err){
    if (mounted.current) {
      setError(err); // Set error if the API call fails
      setLoading(false); // Ensure loading is set to false on error
    }
  }

    // LayoutTeamHeader
    const LayoutTeamHeader = () => (
      <div className="layout-team-header">
      {/* <span className="subtitle" style={{ color: THEME.color }}>
        Team members
      </span> */}
      <h1 style={{ color: "#fff", fontSize: '36px' }}>
        Earn Points for Completing Everyday Tasks
      </h1>
      <p style={{ color: "#fff", fontSize: '20px' }}>
        Find offers from famous brands and be rewarded with gift cards.
      </p>
    </div>
    );

    // LayoutFooter
    const LayoutFooter = () => (
      <div className={["layout-footer", styles.footer].join(" ")}>
      <span>
        <b style={{ color: THEME.color }}>Get started </b>
        <br />
        Earning Rewards.
      </span>
      <Link to="/offers">
        <Button
          to="/offers"
          type="solid-color-tw"
          hoverType="solid-pink-tw"
          round
        >
          Latest Offers
        </Button>
      </Link>
    </div>
    );

    // static data
    const teamList = data.map((item, index) => (
      <article key={index} className={styles.article}>
        <CardImage image={item.creativeImage} label="Trending">
          <div className={styles.card}>
            <span>{item.title}</span>
            <h3>{truncateString(item.offerDescription, 50)}</h3>
            <Button
              round
              after="&#xf107;"
              type="solid-footerBgColor-tw"
              hoverType="solid-color-tw"
              url={item.trackingLink}
            >
              Click to Earn 
            </Button>
          </div>
        </CardImage>
      </article>
    )).slice(0,3);

    //if (loading) return <div>Loadding...</div>;
    if (loading) return (
      // <div>Loading ...</div>
      <Layout
        classNameExtra="layout-team"
        style={{
        //background: 'linear-gradient(90deg, rgba(208,111,3,1) 0%, rgba(215,143,11,1) 35%, rgba(215,135,9,1) 100%)',
        backgroundImage: "url(" + state.data.header_bgs.texture_7 + ")",
      }}
      mode="dark"
      col="1"
      padding
    >
      <LayoutTeamHeader />

      <div className="layout-team-header my-component-container">         
        <h1 style={{ color: "#fff", fontSize: '32px' }}>
           <Dots style={{fontSize:'1em'}}/>        

        </h1>
        {/* <div className="spinner"></div> */}
      </div>

      <LayoutFooter />
    </Layout>
    );

    // mapping from API fetch
    const offerList = list?.map((list, index) => (
      <article key={index} className={styles.article}>
      <div>
        <CardImage image={list.creativeImage} label="Trending">
          <div className={styles.card}>
            <span>{list.title}</span>
            <h3>{truncateString(list.offerDescription, 50)}</h3>
            { list.status !== "none" ?              
              <Button 
                click={() => {
                  window.open(list.trackingLink, '_blank').focus();
                }}
                type="solid-footerBgColor-tw"
                hoverType="solid-color-tw"
                round
              >
                Earn {list.amount}
              </Button>
              : 
              <Button 
                to={`/offer/o/${list.id}`}
                type="solid-footerBgColor-tw"
                hoverType="solid-color-tw"
                round
              >
                Click to Earn
              </Button>
            }
          </div>
        </CardImage>
        </div>
      </article>
    ))
    .slice(0,3);      

  return (
    <Layout
      classNameExtra="layout-team"
      style={{
        backgroundImage: "url(" + state.data.header_bgs.texture_7 + ")",
      }}
      mode="dark"
      col="3"
      padding
    >
      <LayoutTeamHeader />

      {error ? (
        // static offers from datbase.js
        teamList
      ) : (
        // no error and not loading Render offerList
        offerList
      )}

      <LayoutFooter />
    </Layout>
  );
};

