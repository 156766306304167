import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { headerGet, getOffers, getOfferRewards } from "../../../services/basicGet";
import Details from "../ParentDetails/Details";
import { truncateText } from "../../../services/utils";
import styles from "./OfferRewards.module.css";
import { useParams } from 'react-router-dom';

export default () => {
    let data = useParams();

    // API
    const [offersAPI, setOffersAPI] = useState(null);
    // Parent to details
    const [selectedCard, setSelectedCard] = useState(null);

    // begin parent details
    const handleMoreDetails = (data) => {
      //console.log(data);
      // findRecord pass data which is an id
      let res = findRecord(data);
      setSelectedCard(res);
    };
  
    const handleBack = () => {
      setSelectedCard(null);
    };

    // find record by id
    function findRecord (recId) {
      const targetId = recId; // Replace with the ID you want to find
      const foundRecord = offersAPI?.find(record => record.id === targetId);

      if (foundRecord) {
          //console.log(foundRecord); // This will log the entire record with the matching ID
          return foundRecord;
      } else {
          //console.log("Record not found");
      }
    }
    // end parent details

  useEffect(() => {
    //console.log('OfferWall loaded');
    getOfferRewards(data.id, function(items){
      const newOffers = [];
      
      items.forEach((offer) => {
        const truncatedTitle = truncateText(offer?.title, 30);
        const truncatedDescription = truncateText(offer?.description, 180);
        newOffers.push({
          ...offer,
          title: truncatedTitle,
          description: truncatedDescription,
        });
      });

      setOffersAPI(newOffers);
    });
  }, [])
  
  if(offersAPI == null || offersAPI.length == 0){
    return null;
  }else{
    return (
      <>
          {/* <div className="gallary-header text-center">
            <h2>
              special packages
            </h2>
            <p>
              Duis aute irure dolor in  velit esse cillum dolore eu fugiat nulla.  
            </p>
          </div>
   */}
     

{
    !selectedCard && 
    <section id="pack" className={ styles.packages }>
			<div className={ styles.container }>
				<div className={ styles.packagesContent } style={{maxWidth:'1200px', margin:'auto'}}>
					<div className="row">
                    { offersAPI.map( offer => 
                        <div className="col-md-4 col-sm-6" key={offer.id}>
                            <div className={ styles.singlePackageItem }>
                                <img
                                    style={{
                                            width: '100%',
                                            height: '300px',
                                    }} 
                                    src={ offer.creativeImage }
                                    alt={offer.title} 
                                />
                                  <div className={ styles.singlePackageItemTxt }>

                                <h3>{ offer.title}
                                    
                                </h3>
                                <div className={ styles.packagesPara }>
                                    <p>
                                        { offer.rewardDescription }
                                    </p>                                    
                                </div>
                                <div className={ styles.packagesReview }>
                                    <p className={ styles.pullRight }>Reward Amount: 
                                        <span className={ styles.pullRight }>{ offer.amount }
                                        </span>
                                    </p>
                                </div>
                                <div className={ styles.aboutBtn }>

                                    <button className="btn btn-primary" onClick={() => handleMoreDetails(offer.id)}>
                                        More Details
                                    </button>

                                </div>
                                </div>
                            </div>
                        </div>
                    )}
					</div>
				</div>

                <div className="gallary-header text-center">
        <Link className="btn btn-primary" to={{pathname: '/offers'}}>Back to Offers</Link>
          </div>
			</div>
		</section>  
}
         
{
    selectedCard && 
    <Details data={selectedCard} onBack={() => {setSelectedCard(null)}} />
}

      
  
      </>
    );
  }
  
};