import React, { useEffect } from 'react';
import { Container, Table } from 'react-bootstrap';
//import { Header } from '../../widgets';
import { useCustomState } from '../../../state/state';
//import '../../../index.css';

const Toc = ({ token, pageTitle="pageTitle" }) => {
  const [state, actions] = useCustomState();

  // for use by page's title
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);  

  return (
    <>
      {/* <Header img={state.data.header_bgs.about}>Table of Pages</Header> */}
      {/* <div>
            <h1 className='faq'></h1>
        </div> */}
      <div>
        <Container>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Category</th>
                <th>Link</th>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>OfferCards</td>
                <td>
                  <a href="/offercards" alt="offercards">
                    Offercards
                  </a>
                </td>
                <td>Offercards</td>
                <td>Works, Not perfect</td>
              </tr>
              <tr>
                <td>Offers List</td>
                <td>
                  <a href="/offerlist" alt="/offersog">
                    OfferList
                  </a>
                </td>
                <td>OG Offers</td>
                <td>Simple Tabled List</td>
              </tr>
              <tr>
                <td>Offers List</td>
                <td>
                  <a href="/offerwall-grid-left-sidebar" alt="offersprops">
                    Grid Left Sidebar
                  </a>
                </td>
                <td>Grid Left Sidebar</td>
                <td>Works, Little work needed</td>
              </tr>
              <tr>
                <td>Offers List</td>
                <td>
                  <a href="/offerwall-list-left-sidebar" alt="offersprops">
                    List Left Sidebar
                  </a>
                </td>
                <td>List Left Sidebar</td>
                <td>Works, Little work needed</td>
              </tr>
              <tr>
                <td>Offers List</td>
                <td>
                  <a href="/offerwall-grid-without-sidebar" alt="offersprops">
                    Grid No Sidebar
                  </a>
                </td>
                <td>Grid No Sidebar</td>
                <td>Works, Ready to use</td>
              </tr>
              <tr>
                <td>Secondary</td>
                <td>
                  <a href="/clicklist" alt="clicklist">
                    Clicklist
                  </a>
                </td>
                <td>Clicklist</td>
                <td>Works, Needs little work</td>
              </tr>              
              <tr>
                <td>Offers List</td>
                <td>
                  <a href="/conversions" alt="conversions">
                    Conversions
                  </a>
                </td>
                <td>Conversions</td>
                <td>Works, Not perfect, Little work needed</td>
              </tr>
              <tr>
                <td>Cart</td>
                <td>
                  <a href="/cart" alt="cart">
                    Cart
                  </a>
                </td>
                <td>Cart</td>
                <td>WIP</td>
              </tr>
              <tr>
                <td>Secondary</td>
                <td>
                  <a href="/sign-up" alt="sign-up">
                    Sign-Up
                  </a>
                </td>
                <td>Sign-Up</td>
                <td>non-modal</td>
              </tr>
              <tr>
                <td>Home Page</td>
                <td>
                  <a href="/home" alt="home">
                    Home
                  </a>
                </td>
                <td>Home</td>
                <td>Works, Needs little final touches</td>
              </tr>
              <tr>
                <td>Home Page</td>
                <td>
                  <a href="/homeparalax" alt="homeparalax">
                    HomeParalax
                  </a>
                </td>
                <td>Home Paralax</td>
                <td>Works, Needs little final touches</td>
              </tr>
              <tr>
                <td>Home Video</td>
                <td>
                  <a href="/homevideo" alt="homevideo">
                    Home Video
                  </a>
                </td>
                <td>Home Video</td>
                <td>Works, Needs little final touches</td>
              </tr>
              <tr>
                <td>Home Image</td>
                <td>
                  <a href="/homeimage" alt="homeimage">
                    Home Image
                  </a>
                </td>
                <td>Home Video</td>
                <td>Works, Needs little final touches</td>
              </tr>                            
              <tr>
                <td>Secondary</td>
                <td>
                  <a href="/ognavbar" alt="/ognavbar">
                    OG Navbar
                  </a>
                </td>
                <td>OG Navbar</td>
                <td>Works, but horrid</td>
              </tr>
            </tbody>
          </Table>

          <h3>The following are disabled at say App.js but exist for future reference and or usage</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Category</th>
                <th>Link</th>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Modal Details</td>
                <td>
                  <a href="/modalexample" alt="Simple Modal Box Example">
                    Simple Modal Box Example
                  </a>
                </td>
                <td>Simple Modal Box Example</td>
                <td>A Modal box example to say display some details</td>
              </tr>
              
            </tbody>
          </Table>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Source</th>
                <th>Icon</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>https://icons8.com/line-awesome</td>
                <td>
                  <i style={{ fontSize: '32px'}} className="lab la-ethereum"></i><br />
                  <i style={{ fontSize: '32px'}} className="las la-dollar-sign"></i><br />
                  <i style={{ fontSize: '32px'}} className="las la-thumbs-up">64, 48, 32, 24, 16 </i><br />
                  <i style={{ fontSize: '32px'}} className="las la-gift">64, 48, 32, 24, 16</i><br />
                  <i style={{ fontSize: '32px'}} className="las la-star"></i><br />
                  <i style={{ fontSize: '32px'}} className="las la-shopping-bag"></i><br />
                  <i style={{ fontSize: '32px'}} className="las la-shopping-cart"></i><br />
                  <i style={{ fontSize: '32px'}} className="las la-shopping-basket"></i><br />
                  <i style={{ fontSize: '32px'}} className="las la-store"></i><br />
                  <i style={{ fontSize: '32px'}} className="las la-user-tag"></i><br />
                </td>
              </tr>
            </tbody>
          </Table>
          
          <div>
            {/* VARIANTS */}
            <ul>
              {/* <li><a href="/offersog" alt="/offersog">OG Offers</a></li> */}
              {/* <li><a href="/offersprops" alt="offersprops">offersprops</a></li> */}
              {/* <li><a href="/offerwall-grid-left-sidebar" alt="offersprops">Grid Left Sidebar</a></li> */}
              {/* <li><a href="/offerwall-list-left-sidebar" alt="offersprops">List Left Sidebar</a></li> */}
              {/* <li><a href="/offerwall-grid-without-sidebar" alt="offersprops">Grid No Sidebar</a></li> */}
              {/* <li><a href="/conversions" alt="conversions">conversions</a></li> */}
            </ul>
          </div>
          {/* <div> */}
          {/* ADMIN DASHBOARDS */}
          {/* <ul> */}
          {/* <li><a href="/dashboard" alt="faq">Dashboard Amin</a></li> */}
          {/* <li><a href="/dashboarduser" alt="faq">Dashboard User</a></li> */}
          {/* </ul> */}
          {/* </div> */}
          <div>
            {/* ORIGINAL PAGES */}
            <ul>
              {/* <li><a href="/services" alt="services">services</a></li> */}
              {/* <li><a href="/products" alt="products">products</a></li> */}
              {/* <li><a href="/sign-up" alt="sign-up">sign-up</a></li> */}
              {/* <li><a href="/clicklist" alt="clicklist">clicklist</a></li> */}
            </ul>
          </div>
        </Container>
      </div>
      {/* end of top row */}

      <div>
        {/* <div>
                <h2>Home Pages</h2>
            </div> */}

        <div>
          {/* HOME PAGES */}
          <ul>
            {/* <li><a href="/home" alt="home">Home</a></li> */}
            {/* <li><a href="/home-paralax" alt="home-paralax">Home Parallax</a></li> */}
            {/* <li><a href="/home-video" alt="home-video">Home Video</a></li> */}
          </ul>
        </div>
        <div>
          <ul>
            {/* External Site */}
            {/* <li><a href="https://academy.binance.com/en/articles/how-to-add-polygon-to-metamask" alt="How to install metamask and configure to polygon network">Article on how to link polygon matic network to wallet</a></li> */}
          </ul>
        </div>
        <div>
          {/* MISC */}
          <ul>
            {/* <li><a href="/login" alt="login">Login ?</a></li> */}
            {/* <li><a href="/ognavbar" alt="/ognavbar">OG Navbar</a></li>                     */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Toc;
