import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Features,
  Services,
  Team,
  Services2,
  Contacts,
  Reviews,
  ParallaxHeader,
  Process,
} from "../../widgets";
import { useCustomState } from "../../../state/state";

import OfferChild from "./OfferChild";

export default ({ pageTitle="pageTitle" }) => {
  const state = useCustomState()[0];
  const { oid } = useParams();

    // API endpoint
    // /offerrewards/{offerId}/offer
    // 4 = sirius, 

  // for use by page's title
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <Fragment>
          {/* <ParallaxHeader /> */}
          <OfferChild offerId={oid} />
          
          {/* <Team data={state.data.offers} /> */}
          {/* <Services2 data={state.data.services} /> */}
          {/* <Services data={state.data.services} /> */}
          {/* <Features data={state.data.features} /> */}


          {/* <Process data={state.data.process} /> */}
          {/* <Reviews data={state.data.reviews} /> */}
          {/* <Contacts /> */}
    </Fragment>
  );
};
