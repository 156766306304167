import React, { useState, useEffect, useRef } from "react";
import styles from "./Lander.module.css";
import { useCustomState } from "../../../state/state";
import { Layout } from "../../layouts";
import { Button } from "../../elements";
import { getOfferRewards } from "../../../services/basicGet";
import "./howtochild.css";

const HowToChild = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [state, actions] = useCustomState();

  // for useRef
  const mounted = useRef(true);
  const handImageRef = useRef(null);
  const alertBoxRef = useRef(null);

  const data = [
    {"itemTitle": "Click to Start the Deal", "itemDesc": "See how your day-to-day tasks fit into the wider vision. Go from tracking progress at the milestone level all the way done to the smallest of details."},
    {"itemTitle": "Click to Start the Deal", "itemDesc": "See how your day-to-day tasks fit into the wider vision. Go from tracking progress at the milestone level all the way done to the smallest of details."},
    {"itemTitle": "Click to Start the Deal", "itemDesc": "See how your day-to-day tasks fit into the wider vision. Go from tracking progress at the milestone level all the way done to the smallest of details."},
  ];

  useEffect(() => {
    //console.log(state.data.offers);
    // for useRef
    mounted.current = true;

    setLoading(true)
    // if list is already populated no need to load
    if (list != null && list.length) {
      //setLoading(false);
      return;
    }
    if(list != null && list.length) {
        return;
    }    

    getOfferRewards(null, 
      function(response) {
        if (!response || response.error || typeof response !== 'object' || Object.keys(response).length === 0) {
          throw new Error('Failed to fetch data'); // Explicitly throw an error if the response is not as expected
        }
        if (mounted.current) {
          setList(response); // Update state with the fetched data
          setLoading(false); // Data is loaded
        }
      }, function(err){
      if (mounted.current) {
        setError(err); // Set error if the API call fails
        setLoading(false); // Ensure loading is set to false on error
      }
    });

    // Cleanup function remains unchanged
    return () => mounted.current = false;
  }, []);


  // anim useEffect
  useEffect(() => {
    
    const handImage = handImageRef.current;
    const alertBox = alertBoxRef.current;

    console.log('Hand Icon are referenced:', handImageRef);
    console.log('Alert Box are referenced:', alertBox);

  // Function to start the hand pointing animation
  function startHandAnimation() {
    console.log('startHandAnimation');
    console.log(handImage.style.display);
   // handImage.style.display = 'block';
    console.log(handImage.style.display);
    //handImage.current.style.animation = 'point 2s infinite';
    setTimeout(() => {
      console.log('Stopping hand animation');
     // handImage.style.animation = 'none';
      stopHandAnimation();
    }, 2000);

    //setTimeout(stopHandAnimation, 2000); // Stop after 2 seconds
}

// Function to stop the hand pointing animation
function stopHandAnimation() {
  console.log('stopHandAnimation');
    //handImage.style.animation = 'none';
    //startAlertAnimation();
}

// Function to start the alert box fade-in animation
// function startAlertAnimation() {
//     alertBox.style.display = 'block';
//     alertBox.style.position = 'absolute';
//     alertBox.style.top = '55%';
//     alertBox.style.left = '15%';
//     alertBox.style.animation = 'fadeIn 1.5s ease-in-out forwards';
//     setTimeout(displayNoneElements, 5000);
// }

// Function to display none elements and then wait for 10 seconds before restarting
// function displayNoneElements() {
//   handImage.style.display = 'none';
//   alertBox.style.display = 'none';
//   setTimeout(startHandAnimation, 1000);
// }

startHandAnimation();
  }, []);  

  const OfferHeader = () => (
    <div className={styles.header}>
      <span className={styles.content}>
        Header
      </span>
    </div>
  );

  const OfferFooter = () => (
    <div className={styles.footer}>Header</div>
  );

  // static data
  const offerItemStatic = state.data.offers.map((item, index) => (
    // <div className={styles.middle} key={index}>{item.title}</div>
    <section key="index" className="padding-block-900Lid">
      <div className="containerLid">
        <div className="even-columnsLid">
          <div style={{ backgroundColor: "#CCC"}} className="flowLid">
            <h1 className="fs-primary-headingLid fw-boldLid">
              { item.title }
            </h1>
            <p>
              { item.offerDescription }
            </p>
            {/* <button className="buttonLid">Get Started</button> */}
            { item.status !== "none" ?              
              <Button 
                click={() => {
                  window.open(item.trackingLink, '_blank').focus();
                }}
                type="solid-footerBgColor-tw"
                hoverType="solid-color-tw"
                round
              >
                Earn {item.amount}
              </Button>
              : 
              <Button 
                to="/offers"
                type="solid-footerBgColor-tw"
                hoverType="solid-color-tw"
                round
              >
                Click to Earn
              </Button>
            }
          </div>
          <div style={{ backgroundColor: "#CCC444"}} className="ctaLid">
            {/* <img src={ state.data.header_bgs.manOnCompSearching } alt="man seaching at search engine site" /> */}
            <img src={ item.creativeImage } alt={ item.title } />
            {/* <img src="images/illustration-intro.svg" alt="" /> */}
          </div>
        </div>
      </div>
    </section>
  )).slice(0,1);

  // if loading is true
  if (loading) return (
    <div
      className={styles.parallax}
      style={{
        backgroundImage: "url(" + state.data.header_bgs.CircularVectorSmartObject + ")",
      }}
      >
      <Layout col="1">
        <div className={styles.intro}>
          <h1>
            Loading ...
          </h1>
        </div>
      </Layout>
    </div>
  );

  const offerItemApi = list.map((list, index) => (
    <section key="index" className="padding-block-900Lid">
    <div className="containerLid">
      <div className="even-columnsLid">
        <div style={{ backgroundColor: "#CCC"}} className="flowLid">
          <h1 className="fs-primary-headingLid fw-boldLid">
            { list.title }
          </h1>
          <p>
            { list.offerDescription }
          </p>
          {/* <button className="buttonLid">Get Started</button> */}
          { list.status !== "none" ?              
            <Button 
              click={() => {
                window.open(list.trackingLink, '_blank').focus();
              }}
              type="solid-footerBgColor-tw"
              hoverType="solid-color-tw"
              round
            >
              Earn {list.amount}
            </Button>
            : 
            <Button 
              to="/offers"
              type="solid-footerBgColor-tw"
              hoverType="solid-color-tw"
              round
            >
              Click to Earn
            </Button>
          }
        </div>
        <div style={{ backgroundColor: "#CCC444"}} className="ctaLid">
          {/* <img src={ state.data.header_bgs.manOnCompSearching } alt="man seaching at search engine site" /> */}
          <img src={ list.creativeImage } alt={ list.title } />
          {/* <img src="images/illustration-intro.svg" alt="" /> */}
        </div>
      </div>
    </div>
  </section>
    // <div className={styles.middle} key={index}>
    //   <div className="card" style={{width: "60%", backgroundColor: "#CCC"}}>
    //     <img style={{ width: "60%"}} src={list.creativeImage} className="card-img-top" alt="..." />
    //     <div className="card-body">
    //       <h5 className="card-title">{list.title}</h5>
    //       <p className="card-text">{list.rewardDescription}</p>
    //       <a href="#" class="btn btn-primary">Go somewhere</a>
    //     </div>
    //   </div>
    // </div>
  )).slice(0,1);
  
  // begin animations
  const AnimationClick = () => (
    <div className="animbody">
      <div className="animOuter-div">
          <div className="animInner-div">
              <div className="animHeader">Header</div>
              <div className="animMiddle-div">
                  {/* <img src="https://via.placeholder.com/300x250" alt="Centered Image"> */}
                  <img style={{ width: "300px" }} src={ state.data.animatedGifs.dealsToModal } alt="text here" />

                <img
                  src={ state.data.goldCoins.oneCoin }
                  alt="Hand"
                  className="hand-image"
                  ref={handImageRef}
                />

                  {/* <i style={{ fontSize: '64px', color: '#000'}} className="las la-hand-point-right hand-icon" ref={handImageRef}></i> */}

                <div
                  className="alert alert-primary custom-alert"
                  ref={alertBoxRef}
                  role="alert"
                >
                  Click Here 
                </div>
              </div>
              <div className="animFooter">Footer</div>
          </div>
      </div>
    </div>
  );

  // end animations

  const HowToStepsBlank = () => (
    <div className="bodyHowToSteps">
      <div className="OutherHowToSteps-div">
        <div className="InnerHowToSteps-div">
          <div className="HeaderHowToSteps">
            Header
          </div>
          <div className="MiddleHowToSteps-div">
            
            <i style={{ fontSize: '64px', color: '#000'}} className="las la-hand-point-right " ref={handImageRef}></i>
            
          </div>
          <div className="FooterHowToSteps">
            Footer
          </div>
        </div>
      </div>
    </div>
  );

  const HowToStepsStatic = () => (
    <div className="bodyHowToSteps">
      <div className="OutherHowToSteps-div">
        <div className="InnerHowToSteps-div">
          <div className="HeaderHowToSteps">
            Header
          </div>
          <div className="MiddleHowToSteps-div">
            
            <ul className="numbered-itemsLid">
              <li>
                <div className="flow" style={{ marginTop: "1em"}}>
                  <h3 className="numbered-items__titleLid fs-600 fw-bold">Click to Strt the Deal</h3>
                  <p className="numbered-items__bodyLid">
                    See how your day-to-day tasks fit into the wider
                    vision. Go from tracking progress at the milestone
                    level all the way done to the smallest of details.
                  </p>
                </div>
              </li>
              <li>
                <div className="flow" style={{ marginTop: "1em"}}>
                  <h3 className="numbered-items__titleLid fs-600 fw-bold">Click to Strt the Deal</h3>
                  <p className="numbered-items__bodyLid">
                    See how your day-to-day tasks fit into the wider
                    vision. Go from tracking progress at the milestone
                    level all the way done to the smallest of details.
                  </p>
                </div>
              </li>
              {/* <li>
                <div className="flow" style={{ marginTop: "1em"}}>
                  <h3 className="numbered-items__titleLid fs-600 fw-bold">Click to Strt the Deal</h3>
                  <p className="numbered-items__bodyLid">
                    See how your day-to-day tasks fit into the wider
                    vision. Go from tracking progress at the milestone
                    level all the way done to the smallest of details.
                  </p>
                </div>
              </li> */}
            </ul>

            {/* <i style={{ fontSize: '64px', color: '#000'}} className="las la-hand-point-right " ref={handImageRef}></i>
             */}
          </div>
          <div className="FooterHowToSteps">
            Footer
          </div>
        </div>
      </div>
    </div>
  );

  const HowToStepsDynamic = ({ items }) => {
    console.log(items);
    return (
      <div className="bodyHowToSteps">
        <div className="OutherHowToSteps-div">
          <div className="InnerHowToSteps-div">
            <div className="HeaderHowToSteps">
              Header
            </div>
            <div className="MiddleHowToSteps-div">
              
              <ul className="numbered-itemsLid">
                
                {
                  items.map((item, index) => (
                    <li key={ index }>
                      <div className="flow" style={{ marginTop: "1em"}}>
                        <h3 className="numbered-items__titleLid fs-600 fw-bold"> { item.itemTitle } </h3>
                        <p className="numbered-items__bodyLid">
                          { item.itemDesc }
                        </p>
                      </div>
                    </li>
                  ))
                }
                
                
              </ul>

              {/* <i style={{ fontSize: '64px', color: '#000'}} className="las la-hand-point-right " ref={handImageRef}></i>
              */}
            </div>
            <div className="FooterHowToSteps">
              Footer
            </div>
          </div>
        </div>
      </div>
  );
  };


  // deprecate
  const TheOffer = () => 
    (
      <section className="padding-block-900Lid">
      <div className="containerLid">
        <div className="even-columnsLid">
          <div className="flowLid">
            <h1 className="fs-primary-headingLid fw-boldLid">Bring everyone
              together to build
              better products.</h1>
            <p>Manage makes it simple for software teams to plan day-to-day tasks while keeping the larger team goals in
              view.</p>
            <button className="buttonLid">Get Started</button>
          </div>
          <div className="ctaLid">
            <img src={ state.data.header_bgs.manOnCompSearching } alt="man seaching at search engine site" />
            {/* <img src="images/illustration-intro.svg" alt="" /> */}
          </div>
        </div>
      </div>
    </section>
    );

  return (
    <>
      <header className="primary-headerLid">
        <div className="containerLid">
          <div style={{ minHeight: "100px", backgroundColor: "#CCC"}} className="nav-wrapperLid">
            {/* <a href="#"><img src="images/logo.svg" alt="Manage" /></a> */}
           
          </div>
        </div>
      </header>

      <main>
        {/* <TheOffer /> */}
        { error ? (
            offerItemStatic
        ) : (
            offerItemApi
        )}

        {/* 1st row How To -> Gifs */}
        <section className="ctaLid | padding-block-900Lid bg-accent-400Lid text-neutral-100Lid">
          <div style={{ backgroundColor: "transparent"}} className="containerLid">
            <div className="even-columnsLid vertical-align-centerLid">
              
              <div style={{ backgroundColor: "rgb(12 21 198)", width: "100%", height: "100%", padding: "4rem"}}>
                <p className="fs-primary-headingLid fw-boldLid">First step find deals you like</p>

                <ul className="numbered-itemsLid">
              <li>
                <div className="flow" style={{ marginTop: "1em"}}>
                  <h3 className="numbered-items__titleLid fs-600 fw-bold">Click to Strt the Deal</h3>
                  <p className="numbered-items__bodyLid">
                    See how your day-to-day tasks fit into the wider
                    vision. Go from tracking progress at the milestone
                    level all the way done to the smallest of details.
                  </p>
                </div>
              </li>
              <li>
                <div className="flow" style={{ marginTop: "1em"}}>
                  <h3 className="numbered-items__titleLid fs-600 fw-bold">Click to Strt the Deal</h3>
                  <p className="numbered-items__bodyLid">
                    See how your day-to-day tasks fit into the wider
                    vision. Go from tracking progress at the milestone
                    level all the way done to the smallest of details.
                  </p>
                </div>
              </li>
              <li>
                <div className="flow" style={{ marginTop: "1em"}}>
                  <h3 className="numbered-items__titleLid fs-600 fw-bold">Click to Strt the Deal</h3>
                  <p className="numbered-items__bodyLid">
                    See how your day-to-day tasks fit into the wider
                    vision. Go from tracking progress at the milestone
                    level all the way done to the smallest of details.
                  </p>
                </div>
              </li>
              <li>
                <div className="flow" style={{ marginTop: "1em"}}>
                  <h3 className="numbered-items__titleLid fs-600 fw-bold">Click to Strt the Deal</h3>
                  <p className="numbered-items__bodyLid">
                    See how your day-to-day tasks fit into the wider
                    vision. Go from tracking progress at the milestone
                    level all the way done to the smallest of details.
                  </p>
                </div>
              </li>
            </ul>
              </div>
              <div style={{ backgroundColor: "#FFAAFF", width: "100%", height: "100%"}} className="justify-self-end-mdLid">
                {/* <button className="buttonLid" data-type="inverted">Get Started</button> */}
                {/* <img style={{ width: "35%" }} src={ state.data.animatedGifs.dealsToModal } alt="text here" />  */}

                {/* animationClick */}
                <p>Hello</p>
                
                < AnimationClick />

              </div>
            </div>
          </div>
        </section>

        {/* 2nd row Gifs -> How To */}
        <section className="ctaLid | padding-block-900Lid bg-accent-400Lid text-neutral-100Lid">
          <div style={{ backgroundColor: "transparent"}} className="containerLid">
            <div className="even-columnsLid vertical-align-centerLid">   
              <div className="justify-self-end-mdLid">
                {/* <button className="buttonLid" data-type="inverted">Get Started</button> */}
                {/* <img style={{ width: "35%" }} src={ state.data.animatedGifs.dealsToModal } alt="text here" />  */}

                {/* animationClick */}
                {/* <p>Hello</p> */}
                < AnimationClick />

              </div>

              <div>
                <p className="fs-primary-headingLid fw-boldLid">First step find deals you like</p>
                <HowToStepsDynamic items={data} />
              </div>
            </div>
          </div>
        </section>
        
        {/* 3rd row How To (blank) -> Gifs */}
        <section className="ctaLid | padding-block-900Lid bg-accent-400Lid text-neutral-100Lid">
          <div style={{ backgroundColor: "transparent"}} className="containerLid">
            <div className="even-columnsLid vertical-align-centerLid">
              
              <div style={{ backgroundColor: "#FFCCFF", width: "100%", height: "100%"}}>
                <HowToStepsBlank />
              </div>
              <div style={{ backgroundColor: "#FFAAFF", width: "100%", height: "100%"}} className="justify-self-end-mdLid">

                < AnimationClick />

              </div>
            </div>
          </div>
        </section>

        {/* 4th row Gifs -> How To */}
        <section className="ctaLid | padding-block-900Lid bg-accent-400Lid text-neutral-100Lid">
          <div style={{ backgroundColor: "transparent"}} className="containerLid">
            <div className="even-columnsLid vertical-align-centerLid">   
              <div className="justify-self-end-mdLid">
                {/* <button className="buttonLid" data-type="inverted">Get Started</button> */}
                {/* <img style={{ width: "35%" }} src={ state.data.animatedGifs.dealsToModal } alt="text here" />  */}

                {/* animationClick */}
                {/* <p>Hello</p> */}
                < AnimationClick />

              </div>

              <div>
                <p className="fs-primary-headingLid fw-boldLid">First step find deals you like</p>
              </div>
            </div>
          </div>
        </section>

        {/* 5th row How To STATIC -> Gifs */}
        <section className="ctaLid | padding-block-900Lid bg-accent-400Lid text-neutral-100Lid">
          <div style={{ backgroundColor: "transparent"}} className="containerLid">
            <div className="even-columnsLid vertical-align-centerLid">
              
              <div style={{ backgroundColor: "#FFCCFF", width: "100%", height: "100%"}}>
                <HowToStepsStatic />
              </div>
              <div style={{ backgroundColor: "#FFAAFF", width: "100%", height: "100%"}} className="justify-self-end-mdLid">

                < AnimationClick />

              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="primary-footerLid | padding-block-700Lid bg-neutral-900Lid text-neutral-100Lid">
        <div className="containerLid">
          <div className="primary-footer-wrapperLid">
            <div className="primary-footer-logo-socialLid">
              <a href="#" aria-label="home">
                {/* <svg className="logo" width="146" height="24">
                  <use xlink:href="images/logo.svg#logo"></use>
                </svg> */}
              </a>

            </div>
            <div className="primary-footer-navLid">
              <nav className="footer-navLid">
                {/* <ul className="flowLid" style={{marginTop: "1em", ariaLabel: "Footer", role: "list"}}>
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Pricing</a></li>
                  <li><a href="#">Products</a></li>
                  <li><a href="#">About Us</a></li>
                  <li><a href="#">Careers</a></li>
                  <li><a href="#">Community</a></li>
                  <li><a href="#">Privacy Policy</a></li>
                </ul> */}
              </nav>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default HowToChild;