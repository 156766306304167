import React, { Fragment, useEffect } from "react";
import Subscribe from '../../Subscribe';
import Footer from '../../layouts/Footer/Footer';
import {
  Features,
  Services,
  Team,
  Services2,
  Contacts,
  Reviews,
  VideoHeader,
  Process,
} from "../../widgets";
import { useCustomState } from "../../../state/state";

export default ({ pageTitle="pageTitle" }) => {
  const state = useCustomState()[0];

  // for page's title element
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle])

  return (
    <Fragment>
          <VideoHeader />
          <div style={{height:'10px', background:'#FFB703'}}></div>
          <Team data={state.data.offers} />
          <div style={{height:'10px', background:'#FFB703'}}></div>
          <Features data={state.data.features} />
          <div style={{height:'10px', background:'#FFB703'}}></div>
          {/* DO NOT USE SERVICES2 IT NEEDS STYLING WOKR */}
          {/* <Services2 data={state.data.services} /> */}
          {/* DO NOT USE SERVICES ITS SCATTERED AND WE DO NOT HAVE ENOUGH TO USE IT */}
          {/* <Services data={state.data.services} /> */}
          {/* <Reviews data={state.data.reviews} /> */}
          {/* <Process data={state.data.process} /> */}
          {/* <Contacts /> */}
          <Subscribe />
          {/* <div style={{height:'2px', background:'#FFB703'}}></div> */}
          {/* <Footer /> */}
    </Fragment>
  );
};
