import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//import '../CSS/LoginNew.css';
import '../../App.css';
import { useCustomState } from '../../state/state';
import { register } from '../../services/authenticate';


// function failHandler(status) {
//   console.log('registeration failed');
// }

export default function SignUp({ setToken, emailRef }) {
  const [state, actions] = useCustomState();
  const [errorMessage, setErrorMessage] = useState();
  const [email, setUserName] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [userCreated, setIsUserCreated] = useState(false);

  useEffect(() => {
    // Reset the error message state when the component is unmounted.
    return () => {
      setErrorMessage(undefined);
    };
  }, [state.modal]);

  // for checkbox
  const handleChange = (e) => {
    setIsChecked(e.target.checked);
  };

  function swapModal() {
    actions.toogleSignup();
    actions.toogleModal();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    // eslint-disable-next-line
    if (email == null || email == '') {
      setErrorMessage('Please provide email!');
      return;
    }
    // eslint-disable-next-line
    if (password == null || password == '') {
      setErrorMessage('Please provide password!');
      return;
    }
    // eslint-disable-next-line
    if (password != confirmPassword) {
      setErrorMessage('Password and Confirm password does not match!');
      return;
    }

    // Check if the checkbox is checked.
    if (!isChecked) {
      // Display an error message to the user.
      //console.log('Please accept the terms before submitting.');
      setErrorMessage(
        'Please read Terms and Conditions as well as Privacy Policy'
      );
      return;
    }

    actions.toogleLoading(true);
      register({email, password, confirmPassword, ConfirmEmailUrl: window.location.origin + '/confirmemail'}, 
        function(res){
          //console.log(res);
          if (res == null) {
            actions.toogleLoading(false);
            return null;
          }
          if (typeof res == 'object') {
            // eslint-disable-next-line
            if (res.status == 'Success') {
              setIsUserCreated(true);
              setErrorMessage(null);

              // event Google Analytics
              if(window.gtag){
                window.gtag('event', 'signup_success', {
                  'app_name': 'upmatic.io',
                  'screen_name': 'SignUpModal'
                });
              }

              // eslint-disable-next-line
            } else if (res.status == 'Failed') {
              // event Google Analytics
              if(window.gtag){
                window.gtag('event', 'signup_failed', {
                'app_name': 'upmatic.io',
                'screen_name': 'SignUpModal'
                });
              }
              setErrorMessage(res.message);
            }
          }
          actions.toogleLoading(false);
      }, 
      function(err){
        if (err?.status == 'Failed') {
          // event Google Analytics
          if(window.gtag){
            window.gtag('event', 'signup_error_failed', {
              'app_name': 'upmatic.io',
              'screen_name': 'SignUpModal'
            });
          }

          setErrorMessage(err.message);
        }
        //console.log('error: ' + err);
        actions.toogleLoading(false);
      });
  };

  return (
    <>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <h1>Sign Up</h1>
          {errorMessage && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FAEFEF',
                height: '40px',
                marginTop: '3px',
                marginBottom: '8px',
                borderRadius: '4px',
                padding: '5px',
                boxShadow: '2px 2px 5px  #EBDEDF',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  color: '#ff0000',
                  fontSize: '14px',
                  lineHeight: '1.5',
                  fontWeight: 500,
                }}
              >
                {errorMessage}
              </div>
            </div>
          )}

          {userCreated && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FAEFEF',
                height: '40px',
                marginTop: '3px',
                marginBottom: '8px',
                borderRadius: '4px',
                padding: '5px',
                boxShadow: '2px 2px 5px  #EBDEDF',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  color: 'green',
                  fontSize: '14px',
                  lineHeight: '1.5',
                  fontWeight: 500,
                }}
              >
                Please check your email to confirm, then log in.
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label>Email</label>
              <input
                ref={emailRef}
                placeholder="Enter email"
                className="form-control inputEmail"
                autoComplete="username"
                type="text"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label>Password</label>
              <input
                placeholder="Enter password"
                className="form-control inputPassword"
                autoComplete="new-password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label>Confirm Password</label>
              <input
                placeholder="Confirm password"
                className="form-control inputPassword"
                autoComplete="new-password"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <p className="">
                By Signing up, you agree to the{' '}
                <a href="/terms" target="_blank">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="/privacy" target="_blank">
                  Privacy Policy.
                </a>
              </p>
              <div className="custom-control custom-checkbox">
                <input
                  style={{ marginRight: '6px' }}
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                  onChange={handleChange}
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  I accept the Terms.
                </label>
              </div>
            </div>
            <div className="mb-3">
              <div className="d-grid">
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              </div>
            </div>
            <p className="forgot-password text-right">
              Already registered{' '}
              <a href="#" onClick={() => swapModal()}>
                sign in?
              </a>
            </p>
          </form>
        </div>
      </div>
    </>
  );
}

SignUp.prototype = {
  setToken: PropTypes.func.isRequired,
};
