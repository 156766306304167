import React, { useEffect, useRef } from 'react';
import styles from './ModalForm.module.css';
// import { Backdrop, ContactForm } from "..";
import { Backdrop, LoginForm } from '..';
import Login from '../../Login/Login';
import { useCustomState } from '../../../state/state';

export default ({ setToken }) => {
  const [state, actions] = useCustomState();
  const emailRef = useRef(null);

  useEffect(() => {
    if (state.modal == true) {
      emailRef.current.focus();
    }
  }, [state.modal]);

  function updateToken(token) {
    //console.log(token);
    setToken(token);
  }

  return (
    <div className={[styles.wrapper, state.modal && styles.show].join(' ')}>
      <div className={styles.modal}>
        <i onClick={() => actions.toogleModal()} className="las la-times" style={{color:'#ffffff'}} />
        {/* <ContactForm /> */}
        <Login
          setToken={setToken}
          emailRef={emailRef}
          updateToken={updateToken}
        />
        {/* <LoginForm /> */}
      </div>
      <Backdrop />
    </div>
  );
};
