import React from 'react';
import { useCustomState } from '../../state/state';
//import { Link } from "../../components/elements";
import THEME from "../../state/theme";
import {logout} from '../../services/authenticate';
import { useNavigate } from "react-router-dom";

export default function LoggedInUser(props) {
    const [state, actions] = useCustomState();
    const navigate = useNavigate();
    function handleButtonClick(e){
        e.preventDefault();
        logout(function(){
            //console.log('default logged out run');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            actions.updateUser(null);
            state.token = null;
            actions.updateUser(null);
            //window.location.reload(true);
            window.location = '/'
            //actions.toogleModal();
        }, function(){
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            actions.updateUser(null);
            state.token = null;
            actions.updateUser(null);
            //window.location.reload(true);
            window.location = '/'
        });
        
    }
    return (
        <>
            <a 
                // style={{ color: THEME.purple }}
                href="#"
                onClick={(e) => { handleButtonClick(e)}}>Log Out
            </a>
        </>
    );
}